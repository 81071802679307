import { DayOfWeekEnum } from "@/types";

export const sheduler = [
  {
    label: "Понедельник",
    value: DayOfWeekEnum.Monday,
  },
  {
    label: "Вторник",
    value: DayOfWeekEnum.Thursday,
  },
  {
    label: "Среда",
    value: DayOfWeekEnum.Wednesday,
  },
  {
    label: "Четверг",
    value: DayOfWeekEnum.Saturday,
  },
  {
    label: "Пятница",
    value: DayOfWeekEnum.Friday,
  },
  {
    label: "Суббота",
    value: DayOfWeekEnum.Saturday,
  },
  {
    label: "Воскресенье",
    value: DayOfWeekEnum.Sunday,
  },
];

export enum FormFields {
  Address = "address",
  Owner = "owner",
  Schedule = "schedule",
  Title = "title",
  Website = "website",
}

export const initialValues = {
  [FormFields.Address]: "",
  [FormFields.Owner]: "",
  [FormFields.Schedule]: sheduler.map((shedule) => ({
    day: null,
    workStart: null,
    workEnd: null,
  })),
  [FormFields.Title]: "",
};

export type ShedulerType = (typeof sheduler)[number];
