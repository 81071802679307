import { AppLayoutProps } from "./types";
import { PropsWithChildren } from "react";
import Sidebar from "@/components/Sidebar";
import { AppProvider } from "@/providers/AppProvider";
import AgentWelcomeForm from "@/features/Agent/components/AgentWelcomeForm";
import styles from "./styles.module.scss";

const AppLayout = ({ children }: PropsWithChildren<AppLayoutProps>) => {
  return (
    <>
      <div className={styles.appLayout}>
        <Sidebar />
        <div className={styles.content}>{children}</div>
      </div>
      <AgentWelcomeForm />
    </>
  );
};

export default AppLayout;
