import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import styles from "./styles.module.scss";
import ModalBody from "@/components/UI/Modal/ModalBody";
import { useOnce } from "@/hooks";
import { useState } from "react";
import IconLoading from "@/components/Icons/IconLoading";
import Table from "@/components/UI/Table";
import { ProductStockEntity } from "@/features/Product/types";
import { isEmpty, map } from "lodash";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import { getWarehouseProductStock } from "@/features/Warehouse/services";
import { ProductOrderModalProps } from "./types";
import Checkbox from "@/components/UI/Checkbox";
import { IndividualAgent } from "@/types";

const ProductOrderModal = ({ isOpen, onClose }: ProductOrderModalProps) => {
  const [isFetching, setIsFetching] = useState(true);
  const [products, setProducts] = useState<ProductStockEntity[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    ProductStockEntity[]
  >([]);

  useOnce(() => {
    getWarehouseProductStock({
      pageSize: 100,
      pageNumber: 1,
    })
      .then((res) => {
        setProducts(res.items);
      })
      .finally(() => {
        setIsFetching(false);
      });
  });

  const handleToggleProduct = (product: ProductStockEntity) => {
    const hasProduct = selectedProducts.find(
      (productItem) => productItem._id === product._id,
    );

    if (hasProduct) {
      setSelectedProducts((prev) =>
        prev.filter((productItem) => productItem._id !== product._id),
      );
    } else {
      setSelectedProducts((prev) => prev.concat(product));
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClose={onClose}>
        <h2>Склад</h2>
      </ModalHeader>
      <ModalBody>
        {isFetching ? (
          <IconLoading />
        ) : (
          <Table
            header={
              <Table.Row>
                <Table.Cell>Наименование</Table.Cell>
                <Table.Cell>Бренд</Table.Cell>
                <Table.Cell>Артикул</Table.Cell>
                <Table.Cell>Кол-во, объем/Мин</Table.Cell>
                <Table.Cell>В резерве</Table.Cell>
                <Table.Cell>Склад</Table.Cell>
                <Table.Cell>Адрес склада</Table.Cell>
                <Table.Cell>Выбрано</Table.Cell>
              </Table.Row>
            }
            isEmpty={!products.length}
            isFetching={isFetching}
          >
            {map(products, (product) => (
              <Table.Row>
                <Table.Cell>
                  <Flex alignItems="center">
                    <Checkbox onClick={() => handleToggleProduct(product)} />
                    {product?.product?.title}
                  </Flex>
                </Table.Cell>
                <Table.Cell>{product?.product?.article}</Table.Cell>
                <Table.Cell>{product?.stock}</Table.Cell>
                <Table.Cell>{product?.reserved}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>{product?.address?.warehouse?.title}</Table.Cell>
                <Table.Cell>{product?.address?.title}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            ))}
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button disabled={isEmpty(products) || isEmpty(selectedProducts)}>
            Добавить в сделку
          </Button>
          <Button onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default ProductOrderModal;
