import api from "@/api";
import { GetLegalAgentsResponse } from "./types";
import { Agent, CounterpartTypeEnum, IndividualAgent } from "@/types";
import { agentSerialize } from "@/pages/AgentDetail/serializer";
import { AgentType } from "@/features/Agent/constants";

export const getIndividualAgent = async (id: string) => {
  const response = await api.get<IndividualAgent>(
    `/individual-counterpart/${id}`,
  );
  return agentSerialize(response.data);
};

export const getLegalAgent = async (id: string) => {
  const response = await api.get<GetLegalAgentsResponse>("/legal-counterpart");
  return response.data;
};

export const fetchAgentById = async (id: string, type: CounterpartTypeEnum) => {
  const url =
    type === CounterpartTypeEnum.Individual
      ? "/individual-counterpart"
      : "/legal-counterpart";

  const response = await api.get<Agent>(`${url}/${id}`);
  return response.data;
};
