import PageTitle from "@/components/PageTitle";
import SearchForm from "@/components/SearchForm";
import type { HeaderProps } from "./types";
import { isValidElement, PropsWithChildren } from "react";
import { If } from "@/components/ConditionalRendering/If";
import styles from "./styles.module.scss";

const Header = ({
  actions,
  children,
  filters,
  showSearch = true,
  title,
}: PropsWithChildren<HeaderProps>) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <PageTitle title={title} />
        <div>{showSearch && <SearchForm />}</div>
        <div className={styles.headerRight}>
          {children}
          <If condition={isValidElement(actions)}>
            <div className={styles.actions}>{actions}</div>
          </If>
          <If condition={isValidElement(filters)}>
            <div className={styles.filters}>{filters}</div>
          </If>
        </div>
      </div>
    </header>
  );
};

export default Header;
