import { Navigate, Routes as ReactRoutes, Route } from "react-router-dom";
import { isNull } from "lodash";
import PrivateRoute from "@/components/PrivateRoute";
import UnauthorizedLayout from "@/layouts/UnauthorizedLayout";
import { useAppSelector } from "@/store";
import { selectIsAuthenticated } from "@/store/slices/userSlice";
import { Auth, Loading, ResetPassword } from "@/pages";
import { pages, PATHS } from "@/constants";

const Routes = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const renderPages = () => {
    return pages.map(({ Component, path, isPrivate }) => {
      const Wrapper = isPrivate ? PrivateRoute : UnauthorizedLayout;

      return (
        <Route
          key={path}
          path={path}
          element={
            <Wrapper>
              <Component />
            </Wrapper>
          }
        />
      );
    });
  };

  return (
    <ReactRoutes>
      {!isAuthenticated && (
        <>
          <Route
            path={PATHS.AUTH}
            element={
              <UnauthorizedLayout>
                <Auth />
              </UnauthorizedLayout>
            }
          />
          <Route
            path={PATHS.RESET_PASSWORD}
            element={
              <UnauthorizedLayout>
                <ResetPassword />
              </UnauthorizedLayout>
            }
          />
        </>
      )}
      {renderPages()}
    </ReactRoutes>
  );
};

export default Routes;
