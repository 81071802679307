import Container from "@/components/Container";
import { Scrollbar } from "react-scrollbars-custom";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import { Form, Formik } from "formik";
import { FormFields } from "./constants";
import DatePicker from "@/components/UI/DatePicker";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import { useOnce } from "@/hooks";
import {
  createWarehouseArrival,
  getWarehouses,
} from "@/features/Warehouse/services";
import { useState } from "react";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import {
  WarehouseArrivalEntity,
  WarehouseEntity,
} from "@/features/Warehouse/types";
import {
  initialValues,
  warehouseArrivalDocuments,
} from "@/features/Warehouse/components/WarehouseArrivalForm/constants";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import { warehouseArrivalFormSchema } from "./schema";
import { useNavigate } from "react-router";
import { EVENTS, PATHS } from "@/constants";
import { triggerEvent } from "@/utils/event";
import { toast } from "react-toastify";
import { getLegalAgents } from "@/pages/AgentDetail/components/AgentCallsListTable/service";
import { CounterpartRoleEnum, IndividualAgent, LegalAgent } from "@/types";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { getAgentsByRole } from "@/features/Agent/utils";
import { getIndividualAgents } from "@/pages/Agents/components/AgentsListTable/service";
import { isAxiosError } from "axios";
import IconAdd from "@/components/Icons/IconAdd";
import Input from "@/components/UI/Input";
import styles from "./styles.module.scss";
import { ProductForm } from "@/features/Product";
import Table from "@/components/UI/Table";
import { If } from "@/components/ConditionalRendering/If";
import { isEmpty } from "lodash";
import { ProductEntity } from "@/features/Product/types";

const WarehouseArrivalForm = () => {
  const { subdivisions } = useAppProvider();
  const navigate = useNavigate();
  const [warehouseArrival, setWarehouseArrival] =
    useState<WarehouseArrivalEntity>(
      // @ts-ignore
      () => initialValues,
    );
  const [showProductModal, setShowProductModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [individualAgents, setIndividualAgents] = useState<IndividualAgent[]>(
    [],
  );
  const [legalAgents, setLegalAgents] = useState<LegalAgent[]>([]);
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseEntity[]>([]);

  useOnce(() => {
    Promise.all([
      getIndividualAgents({
        pageNumber: 1,
        pageSize: 100,
      }),
      getLegalAgents(),
      getWarehouses(),
    ])
      .then(([individual, legal, wh]) => {
        setIndividualAgents(
          // @ts-ignore
          getAgentsByRole(individual?.items, CounterpartRoleEnum.Employee),
        );
        setLegalAgents(
          // @ts-ignore
          getAgentsByRole(legal?.items, CounterpartRoleEnum.Supplier),
        );
        setWarehouses(wh?.items);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const goBack = () => {
    navigate(PATHS.ARRIVAL);
  };

  const handleUpdateProducts = async (product: ProductEntity) => {
    setProducts((prev) => prev.concat(product));
  };

  const handleOnSubmit = async (values: WarehouseArrivalEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      const data = {
        ...values,
        products: products.map((product) => product._id),
      };
      await createWarehouseArrival(data);
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Поступление добавлено");
      navigate(PATHS.ARRIVAL);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    } finally {
      setIsFetching(false);
    }
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <div className={styles.container}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        // @ts-ignore
        initialValues={warehouseArrival}
        onSubmit={handleOnSubmit}
        validationSchema={warehouseArrivalFormSchema}
      >
        {({ values, setFieldValue, handleChange, errors }) => (
          <Form className={styles.form}>
            <Scrollbar>
              <Container center>
                <h2 className={styles.title}>Новое поступление</h2>
                <Flex flexDirection="column" rowGap="xl">
                  <Grid gap="sm">
                    <Grid.Item col={4}>
                      <Select
                        label="Подразделение"
                        options={subdivisions.map((subdivision) => ({
                          label: subdivision.title,
                          value: subdivision._id,
                        }))}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <Select
                        label="Организация (юр.лицо)"
                        onChange={(e) => {}}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <Select
                        error={errors[FormFields.DocumentType]}
                        name={FormFields.DocumentType}
                        label="Тип документа"
                        options={Object.entries(warehouseArrivalDocuments).map(
                          ([value, label]) => ({
                            label,
                            value,
                          }),
                        )}
                        onChange={(e) => {
                          setFieldValue(FormFields.DocumentType, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <Select
                        error={errors[FormFields.Supplier]}
                        name={FormFields.Supplier}
                        label="Поставщик"
                        options={legalAgents.map((legalAgent) => ({
                          label: getAgentFullName(
                            legalAgent?.representative?.[0],
                          ),
                          value: legalAgent.account?._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Supplier, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <Select
                        label="Склад"
                        error={errors[FormFields.Warehouse]}
                        name={FormFields.Warehouse}
                        options={warehouses?.map((warehouse) => ({
                          label: warehouse.title,
                          value: warehouse._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Warehouse, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <Select
                        error={errors[FormFields.Responsible]}
                        name={FormFields.Responsible}
                        label="Ответственный менеджер"
                        options={individualAgents.map((legalAgent) => ({
                          label: getAgentFullName(legalAgent),
                          value: legalAgent?.account?._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Responsible, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={12}>
                      <DatePicker
                        // @ts-ignore
                        date={values[FormFields.AccountingDate]}
                        error={errors[FormFields.AccountingDate]}
                        name={FormFields.AccountingDate}
                        maxDate={new Date()}
                        label="Дата учета"
                        placeholder="Выберите дату учета"
                        onChange={(value: Date) => {
                          setFieldValue(
                            FormFields.AccountingDate,
                            new Date(value),
                          );
                        }}
                      />
                    </Grid.Item>
                  </Grid>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                      <span>Товары</span>
                      <div className={styles.formSearch}>
                        <Input placeholder="Поиск товаров для добавления в таблицу" />
                      </div>
                      <Button
                        endIcon={IconAdd}
                        onClick={() => setShowProductModal(true)}
                        variant="secondary"
                      >
                        Товары
                      </Button>
                    </Flex>
                    <Flex>
                      <Button variant="empty-primary">Из остатков</Button>
                      <Button variant="empty-primary">Из номенклатуры</Button>
                    </Flex>
                  </Flex>
                  <If condition={!isEmpty(products)}>
                    <Table
                      header={
                        <Table.Row>
                          <Table.Cell>Название</Table.Cell>
                          <Table.Cell>Бренд</Table.Cell>
                          <Table.Cell>Артикул</Table.Cell>
                          <Table.Cell>Количество</Table.Cell>
                          <Table.Cell>Предыдущая цена поставки</Table.Cell>
                          <Table.Cell>Текущая цена поставки</Table.Cell>
                          <Table.Cell>Итоговая сумма</Table.Cell>
                          <Table.Cell>Остатки</Table.Cell>
                        </Table.Row>
                      }
                    >
                      {products?.map((product, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{product.title}</Table.Cell>
                          <Table.Cell>{product?.brand}</Table.Cell>
                          <Table.Cell>{product?.article}</Table.Cell>
                          <Table.Cell>{}</Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell>{product?.pricePerVolume}</Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                      ))}
                    </Table>
                  </If>
                </Flex>
              </Container>
            </Scrollbar>
            <Flex
              className={styles.formActions}
              alignItems="center"
              columnGap="sm"
            >
              <Button
                disabled={isFetching}
                isLoading={isFetching}
                type="submit"
              >
                Принять поставку
              </Button>
              <Button
                disabled={isFetching}
                onClick={goBack}
                variant="secondary"
              >
                Закрыть
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>

      <ProductForm
        isOpen={showProductModal}
        onClose={() => setShowProductModal(false)}
        onSuccess={handleUpdateProducts}
      />
    </div>
  );
};

export default WarehouseArrivalForm;
