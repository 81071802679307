import Header from "@/components/Header";

const Calendar = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Calendar;
