import { AppProviderContextValue } from "./types";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { first, isEmpty } from "lodash";
import {
  CarServiceSubdivisionEntity,
  GetSubdivisionsResponse,
} from "@/features/Subdivision/types";
import { createPortal } from "react-dom";
import Overlay from "@/components/UI/Overlay";
import { setCookie } from "@/utils/cookies";
import { SUBDIVISION } from "@/constants";
import noop from "lodash/noop";
import { useAppSelector } from "@/store";
import { selectUser } from "@/store/slices/userSlice";
import api from "@/api";
import { getUserGroups } from "@/features/Group/services";
import { UserGroupEntity } from "@/features/Group/types";

export const AppProviderContext = createContext<AppProviderContextValue>({
  appIsReady: false,
  showWelcomeModal: false,
  subdivisions: [],
  userGroups: [],
  currentSubdivision: null,
  onSetCurrentSubdivision: noop,
  handleCloseWelcomeModal: noop,
  getSubdivisions: noop,
});

export const AppProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [appIsReady, setAppIsReady] = useState(false);
  const user = useAppSelector(selectUser);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [subdivisions, setSubdivisions] = useState<
    CarServiceSubdivisionEntity[]
  >([]);
  const [userGroups, setUserGroups] = useState<UserGroupEntity[]>([]);
  const [currentSubdivision, setCurrentSubdivision] =
    useState<CarServiceSubdivisionEntity | null>(null);

  const onSetCurrentSubdivision = (subdivisionId: string) => {
    setCookie(SUBDIVISION, subdivisionId, 365);
    const item = subdivisions.find((s) => s?._id === subdivisionId);
    item && setCurrentSubdivision(item);
  };

  const handleCloseWelcomeModal = useCallback(() => {
    setShowWelcomeModal(false);
  }, []);

  useEffect(() => {
    if (!user?._id) return;

    const initData = async () => {
      try {
        const [{ items: subdivisionItems }, { items: userGroups }] =
          await Promise.all([getSubdivisions(), getUserGroups()]);

        setUserGroups(userGroups);
        setSubdivisions(subdivisionItems);
        setShowWelcomeModal(isEmpty(subdivisionItems));
        setCurrentSubdivision(first(subdivisionItems) || null);
      } catch (err) {
      } finally {
        setAppIsReady(true);
      }
    };

    initData();
  }, [user?._id]);

  const getSubdivisions = async () => {
    const { data } = await api.get<GetSubdivisionsResponse>(
      `/car-service-subdivision`,
      {
        onDownloadProgress(progressEvent) {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / Number(progressEvent.total),
          );
        },
      },
    );

    return data;
  };

  const lockApp = useCallback(() => {
    setIsOpen(true);
  }, []);

  const unlockApp = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      appIsReady,
      currentSubdivision,
      lockApp,
      unlockApp,
      subdivisions,
      showWelcomeModal,
      onSetCurrentSubdivision,
      handleCloseWelcomeModal,
      getSubdivisions,
      userGroups,
    }),
    [
      appIsReady,
      currentSubdivision,
      lockApp,
      unlockApp,
      subdivisions,
      showWelcomeModal,
      userGroups,
    ],
  );

  return (
    <AppProviderContext.Provider value={value}>
      {children}
      {showWelcomeModal
        ? createPortal(<Overlay />, globalThis?.document.body)
        : null}
    </AppProviderContext.Provider>
  );
};
