import styles from "@/features/Car/components/Cars/styles.module.scss";
import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import { If } from "@/components/ConditionalRendering/If";
import Section from "@/components/UI/Section";
import Input from "@/components/UI/Input";
import Button from "@/components/UI/Button";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import { CarFormProps } from "./types";
import { carColors, carEngineTypes, FormFields } from "./constants";
import { carFormSchema } from "./schema";
import { useState } from "react";
import {
  addCar,
  decodeVin,
  getCarGenerationsByModel,
  getCarModificationsByGeneration,
  getCarSeriesByGeneration,
  searchCarsByBrand,
  searchCarsByModel,
} from "@/features/Car/services";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import {
  CarBrandEntity,
  CarEntity,
  CarGenerationEntity,
  CarModelEntity,
  CarModificationEntity,
  CarSeriesEntity,
} from "@/features/Car/components/Cars/types";
import { isEmpty } from "lodash";
import { ValidationError } from "yup";
import { getYupInnerErrors } from "@/utils/getYupInnerErrors";
import { carInitialValues } from "@/features/Car/components/CarForm/constants";
import InputNumber from "@/components/UI/InputNumber";

const CarForm = ({ id, onRemove, onSuccess }: CarFormProps) => {
  const [car, setCar] = useState<CarEntity>(
    // @ts-ignore
    () => carInitialValues,
  );
  const [vin, setVin] = useState<string>("");
  const [isFullForm, setIsFullForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [brands, setBrands] = useState<CarBrandEntity[]>([]);
  const [models, setModels] = useState<CarModelEntity[]>([]);
  const [generations, setGenerations] = useState<CarGenerationEntity[]>([]);
  const [modifications, setModifications] = useState<CarModificationEntity[]>(
    [],
  );
  const [errors, setErrors] = useState<CarEntity>(
    // @ts-ignore
    () => carInitialValues,
  ); // @ts-ignore
  const [series, setSeries] = useState<CarSeriesEntity[]>([]);

  const handleSearchCarsByBrand = async ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    try {
      const data = await searchCarsByBrand("", value);

      // @ts-ignore
      setCar((prev) => ({
        ...prev,
        carType: label,
      }));

      setBrands(data);
    } catch (err) {}
  };

  const handleSearchCarModificationsBySeries = async ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    try {
      const data = await getCarModificationsByGeneration(value);

      setModifications(data);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(
          err?.response?.data?.error ||
            err?.response?.data?.message ||
            err?.response?.data,
        );
      }
    }
  };

  const handleSearchCarSeriesByGeneration = async ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    try {
      const data = await getCarSeriesByGeneration(value);

      // @ts-ignore
      setCar((prev) => ({
        ...prev,
        generation: label,
      }));

      setSeries(data);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(
          err?.response?.data?.error ||
            err?.response?.data?.message ||
            err?.response?.data,
        );
      }
    }
  };

  const handleSearchCarsByModel = async ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    try {
      const data = await searchCarsByModel(value);

      setCar((prev) => ({
        ...prev,
        brand: label,
      }));

      setModels(data);
    } catch (err) {}
  };

  const handleSearchCarGenerationsByModel = async ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    try {
      const data = await getCarGenerationsByModel(value);

      setCar((prev) => ({
        ...prev,
        model: label,
      }));

      setGenerations(data);
    } catch (err) {}
  };

  const handleToggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleRemove = () => {
    onRemove(id);
  };

  const handleDecodeVin = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      const decodedCar = await decodeVin(vin);
      setCar((prev) => ({ ...prev, ...decodedCar }));
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.message || err?.response?.data);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleValidate = async () => {
    try {
      await carFormSchema.validate(car, {
        abortEarly: false,
      });

      const carResponse = await addCar(car);

      onSuccess(carResponse);
      handleRemove();
    } catch (err) {
      if (err instanceof ValidationError) {
        // @ts-ignore
        setErrors(getYupInnerErrors(err));
      }
    }
  };

  const handleChange = (field: FormFields, value: unknown) => {
    setCar((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Section className={styles.section}>
      <Flex
        className={styles.sectionTop}
        alignItems="center"
        justifyContent="space-between"
      >
        <h2 className={styles.formTitle}>Новый автомобиль</h2>
        <IconButton
          variant="stroke"
          icon={isExpanded ? IconArrowDown : IconArrowUp}
          onClick={handleToggleExpanded}
        />
      </Flex>
      <If condition={isExpanded}>
        <Section className={styles.expandedSection} padding="md">
          <Flex alignItems="flex-end" columnGap="sm">
            <Input
              onChange={(e) => setVin(e.target.value)}
              value={vin}
              label="Vin-код"
              maxLength={17}
              placeholder="Введите vin-код"
            />
            <Button
              disabled={isFetching || !vin.trim().length}
              onClick={handleDecodeVin}
              type="button"
              large
            >
              Распознать
            </Button>
          </Flex>
        </Section>
        <Grid className={styles.content}>
          <Grid.Item col={3}>
            <Select
              name={FormFields.CarType}
              // @ts-ignore
              error={errors[FormFields.CarType]}
              label="Тип транспортного средства"
              // @ts-ignore
              onChange={handleSearchCarsByBrand}
              options={[
                {
                  label: "Легковые",
                  value: "000000000000000000000001",
                },
              ]}
            />
          </Grid.Item>
          <Grid.Item col={3}>
            <Input
              name={FormFields.LicensePlate}
              error={errors[FormFields.LicensePlate]}
              label="Гос. номер"
              onChange={(e) => {
                handleChange(FormFields.LicensePlate, e.currentTarget.value);
              }}
              placeholder="Введите гос. номер"
            />
          </Grid.Item>
          <Grid.Item col={3}>
            <Select
              label="Бренд"
              value={{
                label: car?.brand,
                value: car?.brand,
              }}
              name={FormFields.Brand}
              error={errors[FormFields.Brand]}
              options={brands.map((brand) => ({
                label: brand.name,
                value: brand._id as string,
              }))}
              // @ts-ignore
              onChange={handleSearchCarsByModel}
              placeholder="Выберите из списка"
            />
          </Grid.Item>
          <Grid.Item col={3}>
            <Select
              isDisabled={isEmpty(models)}
              name={FormFields.Model}
              error={errors[FormFields.Model]}
              label="Модель"
              options={models.map((model) => ({
                label: model.name,
                value: model._id,
              }))}
              // @ts-ignore
              onChange={handleSearchCarGenerationsByModel}
              placeholder="Выберите из списка"
            />
          </Grid.Item>
        </Grid>
        <Grid>
          <Grid.Item col={3}>
            <Select
              isDisabled={isEmpty(generations)}
              name={FormFields.Generation}
              error={errors[FormFields.Generation]}
              options={generations.map((generation) => ({
                label: generation.name,
                value: generation._id,
              }))}
              // @ts-ignore
              onChange={handleSearchCarSeriesByGeneration}
              label="Поколение"
              placeholder="Выберите из списка"
            />
          </Grid.Item>
          <Grid.Item col={3}>
            <Select
              isDisabled={isEmpty(series)}
              name={FormFields.Series}
              error={errors[FormFields.Series]}
              options={series.map((s) => ({
                label: s.name,
                value: s._id,
              }))}
              // @ts-ignore
              onChange={handleSearchCarModificationsBySeries}
              label="Серия"
              placeholder="Выберите из списка"
            />
          </Grid.Item>
          <Grid.Item col={3}>
            <Select
              isDisabled={isEmpty(modifications)}
              error={errors[FormFields.Modification]}
              name={FormFields.Modification}
              options={modifications.map((modification) => ({
                label: modification.name,
                value: modification._id,
              }))}
              onChange={(e) => {
                // @ts-ignore
                setCar((prev) => ({
                  ...prev,
                  modification: e?.label,
                }));
              }}
              label="Модификация"
              placeholder="Выберите из списка"
            />
          </Grid.Item>

          <Grid.Item col={3}>
            <InputNumber
              name={FormFields.Year}
              // @ts-ignore
              error={errors[FormFields.Year]}
              label="Год производства"
              placeholder="2020"
              onChange={(e) => {
                handleChange(FormFields.Year, e.currentTarget.value);
              }}
              value={car[FormFields.Year]}
            />
          </Grid.Item>
        </Grid>
        <Button
          className={styles.readMoreBtn}
          endIcon={isFullForm ? IconArrowUp : IconArrowDown}
          onClick={() => setIsFullForm((prev) => !prev)}
          variant="empty-primary"
        >
          Подробнее
        </Button>
        <If condition={isFullForm}>
          <Grid>
            <Grid.Item col={3}>
              <Input
                name={FormFields.STS}
                error={errors[FormFields.STS]}
                onChange={(e) => {
                  handleChange(FormFields.STS, e.currentTarget.value);
                }}
                label="СТС"
                placeholder="Введите СТС"
              />
            </Grid.Item>
            <Grid.Item col={3}>
              <Input
                name={FormFields.PTS}
                error={errors[FormFields.PTS]}
                onChange={(e) => {
                  handleChange(FormFields.PTS, e.currentTarget.value);
                }}
                label="ПТС"
                placeholder="Введите ПТС"
              />
            </Grid.Item>
            <Grid.Item col={3}>
              <Input
                name={FormFields.OwnerAddress}
                error={errors[FormFields.OwnerAddress]}
                onChange={(e) => {
                  handleChange(FormFields.OwnerAddress, e.currentTarget.value);
                }}
                label="Адрес владельца"
                placeholder="Введите адрес владельца"
              />
            </Grid.Item>
            <Grid.Item col={3}>
              <Input
                name={FormFields.Owner}
                // @ts-ignore
                error={errors[FormFields.Owner]}
                onChange={(e) => {
                  handleChange(FormFields.Owner, e.currentTarget.value);
                }}
                label="Владелец"
                placeholder="Введите ФИО владельца"
              />
            </Grid.Item>
            <Grid.Item col={4}>
              <Input
                name={FormFields.Mileage}
                error={errors[FormFields.Mileage]}
                onChange={(e) => {
                  handleChange(FormFields.Mileage, e.currentTarget.value);
                }}
                label="Пробег"
                placeholder="Введите пробег"
              />
            </Grid.Item>
            <Grid.Item col={4}>
              <Select
                name={FormFields.Color}
                error={errors[FormFields.Color]}
                onChange={(e) => {
                  handleChange(FormFields.Color, e?.value);
                }}
                options={Object.entries(carColors).map(([value, label]) => ({
                  label,
                  value,
                }))}
                label="Цвет"
                placeholder="Введите цвет"
              />
            </Grid.Item>
            <Grid.Item col={4}>
              <Select
                name={FormFields.EngineType}
                error={errors[FormFields.EngineType]}
                options={Object.entries(carEngineTypes).map(
                  ([value, label]) => ({
                    label,
                    value,
                  }),
                )}
                onChange={(e) => {
                  handleChange(FormFields.EngineType, e?.value);
                }}
                label="Тип двигателя"
                placeholder="Введите тип двигателя"
              />
            </Grid.Item>
          </Grid>
        </If>
        <Flex className={styles.footer}>
          <Button onClick={handleValidate}>Сохранить</Button>
          <Button onClick={handleRemove} variant="stroke-danger">
            Удалить
          </Button>
        </Flex>
      </If>
    </Section>
  );
};

export default CarForm;
