import Input from "@/components/UI/Input";
import { useFormikContext } from "formik";
import { IndividualAgent } from "@/types";
import { ChangeEvent, useEffect } from "react";
import { EMPTY_STRING } from "@/constants";
import { emailNotAvailable } from "@/utils/validationMessages";
import { FormFields } from "@/features/Agent/components/AgentForm/constants";
import { checkEmailIsAvailable } from "@/features/Agent/service";

const AgentEmail = () => {
  const {
    errors,
    handleChange,
    setFieldError,
    values: { email },
  } = useFormikContext<IndividualAgent>();

  useEffect(() => {
    if (errors[FormFields.Email]) {
      alert();
      setFieldError(FormFields.Email, "");
    }
  }, [errors[FormFields.Email]]);

  // useEffect(() => {
  //   setFieldError(FormFields.Email, EMPTY_STRING);
  //
  //   if (!email.length) return;
  //
  //   checkEmailIsAvailable(email).then(({ available }) => {
  //     if (!available) {
  //       setFieldError(FormFields.Email, emailNotAvailable);
  //     }
  //   });
  // }, [email]);

  return (
    <Input
      error={errors[FormFields.Email]}
      label="E-mail"
      name={FormFields.Email}
      onChange={handleChange}
      placeholder="Введите e-mail"
      value={email}
    />
  );
};

export default AgentEmail;
