import qs from "qs";
import api from "@/api";
import {
  AgentsFromServer,
  GetIndividualAgentsRequestPayload,
  GetIndividualAgentsResponse,
  GetIndividualAgentsService,
  GetLegalAgentsResponse,
} from "./types";
import { agentsSerialize } from "./serializer";

export const getIndividualAgents: GetIndividualAgentsService = async ({
  pageNumber,
  pageSize,
}) => {
  const params: GetIndividualAgentsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<AgentsFromServer>("/individual-counterpart", {
    params,
  });

  return agentsSerialize(response.data);
};

export const getLegalAgents = async () => {
  const response = await api.get("/legal-counterpart");
  return response.data;
};
