import type { ReactNode } from "react";
import { WalletEntity } from "@/features/Wallet/types";
import { CarServiceSectionEntity } from "@/features/Order/types";
import { CarColorEnum } from "@/features/Car/types";

export type EntityType = "legal" | "individual";

export type RoleType = "admin" | "car-service-owner";

export interface UploadFile {
  file: File;
}

export type ExpenseEntity = {
  _id: string;
  title: string;
};

export enum CounterpartTypeEnum {
  Legal = "LegalCounterpart",
  Individual = "IndividualCounterpart",
}

export enum CounterpartRoleEnum {
  Enterprise = "enterprise",
  Office = "office",
  Client = "client",
  Supplier = "supplier",
  Employee = "employee",
  Holding = "holding",
  Carrier = "carrier",
  Retail = "retail",
  Representative = "representative",
  FormerEmployee = "former-employee",
  FormerSupplier = "former-supplier",
}

export interface UploadFileResponse {
  _id: string;
  link: string;
}

export interface FileEntity {
  name: string;
  link: string;
  mimeType: string;
  size: number;
}

export type UserType = {
  _id: string;
  account?: string;
  avatar?: FileEntity;
  discount?: number;
  birthDate?: string;
  balance: number;
  email: string;
  token?: string;
  phone?: string;
  entityType: EntityType;
  role: RoleType;
  cars?: CarEntity[];
  firstName?: string;
  lastName?: string;
  middleName?: string;
  wallet?: WalletEntity;
  owner: CarServiceOwnerEntity;
};

export enum CarTypeEnum {}

export enum CarBodyTypeEnum {
  Sedan = "sedan",
  Hatchback = "hatchback",
  SUV = "suv",
  Crossover = "crossover",
  Coupe = "coupe",
  Convertible = "convertible",
  Wagon = "wagon",
  PickupTruck = "pickup-truck",
  Minivan = "minivan",
  Van = "van",
}

export interface IterableEntity {
  _id: string;
}

export enum CarEngineTypeEnum {
  Petrol = "petrol",
  Diesel = "diesel",
  Electric = "electric",
  Hybrid = "hybrid",
  Hydrogen = "hydrogen",
  NaturalGas = "natural-gas",
  Biofuel = "biofuel",
}

export interface CarEntity {
  _id: string;
  vin?: string;
  carType: CarTypeEnum;
  bodyType: CarBodyTypeEnum;
  title: string;
  licensePlate: string;
  brand: string;
  model: string;
  modification: string;
  generation: string;
  year: number;
  STS: string;
  PTS: string;
  ownerAddress: string;
  ownerFIO: string;
  mileage: string;
  color: CarColorEnum;
  engineType: CarEngineTypeEnum;
  maintenanceDate: Date;
  owner: CounterpartAccountEntity;
}

export enum SocialMediaPlatformEnum {
  Whatsapp = "whatsapp",
  Vk = "vk",
  Tiktok = "tiktok",
  Instagram = "instagram",
  Telegram = "telegram",
}

export interface CounterpartAccountEntity {
  _id: string;
  email: string;
  cars?: CarEntity[];
  passwordHash: string;
  counterpartProfile: UserType;
  counterpartType: CounterpartTypeEnum;
  role: CounterpartRoleEnum;
  recoveryToken?: string;
  balance: number;
  isDummy: boolean;
  wallet: WalletEntity;
}

export interface SocialMediaHandlerEntity {
  platform: SocialMediaPlatformEnum | null;
  nickname: string | null;
}

export enum SexEnum {
  Male = "male",
  Female = "female",
}

export interface Agent {
  _id: string;
  cars?: CarEntity[];
  phone: string;
  discount: number;
  role: CounterpartRoleEnum;
  firstName: string;
  lastName: string;
  middleName: string;

  account: CounterpartAccount;
}

export interface CounterpartAccount {
  _id: string;
  role: CounterpartRoleEnum;
  counterpartProfile: any;
}

export interface LegalAgent extends Agent {
  inn: string;
  role: CounterpartRoleEnum;
  subscribeForNews?: boolean;
  mailAddress: string;
  representative: LegalAgent[];
  officialAddress: string;
  legalEntityType: LegalEntityTypeEnum;
  title: string;
}

export interface IndividualAgent extends Agent {
  avatar: {
    _id: string;
    link: string;
  };
  id?: string;
  account: CounterpartAccountEntity;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: Date | null;
  isEmployee?: boolean;
  email: string;
  representative: IndividualAgent[];
  sex: SexEnum;
  postAddress?: string;
  website?: string;
  subscribeForNews: boolean;
  socialMedia: SocialMediaHandlerEntity[];
  city: string;
}

export interface MetaPagination {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface Owner {
  _id: string;
  subdivision: string;
  title: string;
  wallet: string;
}

export type OwnerType = "PaymentTerminal";

export type TransitionEasing =
  | "easeInCubic"
  | "easeOutCubic"
  | "easeInOutCubic";

interface TransitionEventHandler {
  (element: HTMLElement): void;
}

export interface BaseTransitionProps {
  in?: boolean;
  appear?: boolean;
  immediateEnter?: boolean;
  immediateExit?: boolean;
  keepMounted?: boolean;
  duration?: number;
  easing?: TransitionEasing;
  children: ReactNode;
  onEnter?: TransitionEventHandler;
  onEntering?: TransitionEventHandler;
  onEntered?: TransitionEventHandler;
  onExit?: TransitionEventHandler;
  onExiting?: TransitionEventHandler;
  onExited?: TransitionEventHandler;
}

export interface ResponseFromService<T> {
  items: T[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface ResponseFromServer<T> {
  items: T[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface CarServiceSubdivisionEntity {
  _id: string;
  increment: number;
  title: string;
  owner: CarServiceOwnerEntity;
  address: string;
  website: string;
  logo?: FileEntity;
  sections: CarServiceSectionEntity[];
  funnels: SalesFunnelEntity[];
  bays: CarServiceBayEntity[];
  schedule: SubdivisionWorkday[];
}

export interface CarServiceOwnerEntity {
  _id: string;
  account: UserType;
  domainName: string;
  companyName?: string;
  address?: string;
  phone?: string;
  companyLogo: FileEntity;
  subdivisions: CarServiceSubdivisionEntity[];
}

export enum LegalEntityTypeEnum {
  LimitedLiabilityCompanies = "llc",
  JointStockCompanies = "jsc",
}

export interface SubdivisionWorkday {
  day: DayOfWeekEnum | null;
  workStart: Date;
  workEnd: Date;
}

export enum DayOfWeekEnum {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

export interface CarServiceBayEntity {
  title: string;
  subdivision: CarServiceSubdivisionEntity;
}

export interface SalesFunnelEntity {
  title: string;
  subdivision: CarServiceSubdivisionEntity;
}

export interface PaginationInput {
  pageNumber?: number;
  pageSize?: number;
}
