import api from "@/api";
import { ProductEntity } from "@/features/Product/types";

export const createProduct = async (body: ProductEntity) => {
  const response = await api.post("/product", body);
  return response.data;
};

export const getProducts = async (body: any) => {
  const response = await api.get("/product", body);
  return response.data;
};
