export const columns = [
  {
    name: "increment",
    title: "Номер поступления",
    params: {
      width: 80,
    },
  },
  {
    name: "supplier",
    title: "Поставщик",
  },
  {
    name: "status",
    title: "Статус",
  },
  {
    name: "createdAt",
    title: "Дата создания",
  },
  {
    name: "warehouse",
    title: "Склад",
  },
  {
    name: "subdivision",
    title: "Подразделение",
  },
  {
    name: "totalBuyingPrice",
    title: "Сумма документа",
  },
  {
    name: "products",
    title: "Количество позиций",
  },
  // {
  //   name: "",
  //   title: "Покупатель",
  // },
  {
    name: "responsible",
    title: "Ответственный менеджер",
    params: {
      width: 120,
    },
  },
];
