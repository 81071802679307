import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { CalendarFormModal } from "@/features/Calendar";
import { useState } from "react";

export const OrderCalendarForm = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        endIcon={IconAdd}
        variant="empty-primary"
      >
        Добавить запись в календарь
      </Button>
      {showModal && (
        <CalendarFormModal
          onClose={() => setShowModal(false)}
          isOpen={showModal}
        />
      )}
    </>
  );
};
