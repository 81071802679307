import { useState } from "react";
import Header from "@/components/Header";
import Tabs from "@/components/UI/Tabs";
import SettingsServices from "../SettingsServices";
import { SettingsTab, settingsTabs } from "../../constants";
import styles from "./styles.module.scss";

const SettingsView = () => {
  const [settingsTab, setSettingsTab] = useState<SettingsTab>(
    SettingsTab.Services,
  );

  return (
    <div>
      <Header title="Основные" showSearch={false} />
      <Tabs
        className={styles.tabs}
        value={settingsTab}
        onSelect={setSettingsTab}
      >
        {settingsTabs.map(({ label, value }) => (
          <Tabs.Item key={value} value={value}>
            {label}
          </Tabs.Item>
        ))}
      </Tabs>

      {settingsTab === SettingsTab.Services && <SettingsServices />}
    </div>
  );
};

export default SettingsView;
