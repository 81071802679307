import Routes from "src/components/Routes";
import { useCheckAuth } from "@/hooks";
import { ToastContainer } from "react-toastify";

const App = () => {
  useCheckAuth();

  return (
    <>
      <Routes />
      <ToastContainer />
    </>
  );
};

export default App;
