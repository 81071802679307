import cn from "classnames";
import type { TableCellProps } from "./types";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const TableCell = ({
  children,
  className,
  width,
}: PropsWithChildren<TableCellProps>) => {
  return (
    <td
      className={cn(styles.cell, className)}
      style={{
        maxWidth: width || "auto",
      }}
    >
      {children}
    </td>
  );
};

export default TableCell;
