import { FileEntity } from "@/types";
import {
  WarehouseAddressEntity,
  WarehouseEntity,
} from "@/features/Warehouse/types";

export interface ProductEntity {
  _id?: string;
  title: string;
  brand: string;
  article: string;
  price: number;
  pricePerItem: number;
  pricePerVolume: number;
  barcode: string;
  avatar: FileEntity;
  sellProductAsVolume: boolean;
  productVolume: ProductVolume[];
  vat: ProductVatEnum;
  manufacturerCountry: string;
}

export interface ProductVolume {
  units: ProductVolumeUnits;
  amount: number;
}

export enum ProductVatEnum {
  NoVat = "no-vat",
  Eighteen = "18%",
  Ten = "10%",
  Twenty = "20%",
}

export enum ProductVolumeUnits {
  Cm = "cm",
  Dm = "dm",
  M = "m",
}

export interface ProductCount {
  product: ProductEntity;
  count: number;
}

export interface ProductStockEntity {
  _id: string;
  address: WarehouseAddressEntity;
  product: ProductEntity;
  stock: number;
  reserved: number;
  minStock: number;
  buyingPrice: number;
}
