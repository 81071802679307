import { useState } from "react";
import Modal from "@/components/UI/Modal";
import { ProductFormProps } from "./types";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import Input from "@/components/UI/Input";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import Switch from "@/components/UI/Switch";
import { initialValues, FormFields, countries, vats } from "./constants";
import { productFormSchema } from "./schema";
import { Form, Formik } from "formik";
import styles from "./styles.module.scss";
import { createProduct } from "@/features/Product/services";
import { ProductEntity, ProductVatEnum } from "@/features/Product/types";
import { toast } from "react-toastify";
import InputNumber from "@/components/UI/InputNumber";
import { dispatchEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { isAxiosError } from "axios";

const ProductForm = ({ isOpen, onClose, onSuccess }: ProductFormProps) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (values: ProductEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    console.log(values, "values");

    try {
      const product = await createProduct(values);
      toast.success("Продукт добавлен");
      dispatchEvent(EVENTS.REFRESH_DATA);
      onSuccess?.(product);
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        enableReinitialize
        // @ts-ignore
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={productFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Добавить товар</h2>
            </ModalHeader>
            <ModalBody>
              <Flex rowGap="md" flexDirection="column">
                <Input
                  error={errors[FormFields.Title]}
                  name={FormFields.Title}
                  onChange={handleChange}
                  value={values[FormFields.Title]}
                  label="Наименование"
                  placeholder="Введите наименование товарной позиции"
                />
                <Grid>
                  <Grid.Item col={4}>
                    <Select
                      error={errors[FormFields.Brand]}
                      name={FormFields.Brand}
                      options={["1"].map((type) => ({
                        label: type,
                        value: type,
                      }))}
                      onChange={(e) => {
                        setFieldValue(FormFields.Brand, e!.value);
                      }}
                      label="Бренд"
                      placeholder="Выберите бренд"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.Article]}
                      name={FormFields.Article}
                      onChange={handleChange}
                      value={values[FormFields.Article]}
                      label="Артикул"
                      placeholder="Введите артикул"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.Barcode]}
                      name={FormFields.Barcode}
                      onChange={handleChange}
                      value={values[FormFields.Barcode]}
                      label="Штрихкод"
                      placeholder="Введите штрихкод"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <InputNumber
                      label="Количество"
                      placeholder="Введите количество"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <InputNumber
                      error={errors[FormFields.PricePerItem]}
                      name={FormFields.PricePerItem}
                      onChange={handleChange}
                      value={values[FormFields.PricePerItem]}
                      label="Цена за одну штуку"
                      placeholder="Введите цену за одну штуку"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <InputNumber
                      error={errors[FormFields.PricePerVolume]}
                      name={FormFields.PricePerVolume}
                      onChange={handleChange}
                      value={values[FormFields.PricePerVolume]}
                      label="Итого"
                      placeholder="Введите итоговое значение"
                    />
                  </Grid.Item>
                </Grid>
                <Select
                  error={errors[FormFields.Vat]}
                  name={FormFields.Vat}
                  label="Ставка"
                  options={Object.keys(vats).map((vat) => ({
                    label: vats[vat as ProductVatEnum],
                    value: vat,
                  }))}
                  onChange={(e) => {
                    setFieldValue(FormFields.Vat, e!.value);
                  }}
                  placeholder="Выберите ставку"
                />
                <Select
                  error={errors[FormFields.ManufacturerCountry]}
                  name={FormFields.ManufacturerCountry}
                  options={countries}
                  onChange={(e) => {
                    setFieldValue(FormFields.ManufacturerCountry, e!.value);
                  }}
                  label="Страна производства"
                  placeholder="Выберите из списка"
                />
                <Switch
                  checked={values[FormFields.SellProductAsVolume]}
                  name={FormFields.SellProductAsVolume}
                  onChange={() => {
                    setFieldValue(
                      FormFields.SellProductAsVolume,
                      !values[FormFields.SellProductAsVolume],
                    );
                  }}
                  value="Продать товарную единицу как объем"
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Сохранить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ProductForm;
