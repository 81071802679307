import Input from "@/components/UI/Input";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import Grid from "@/components/UI/Grid";
import { useCallback, useState } from "react";
import { ProductForm, ProductOrderModal } from "@/features/Product";
import { toast } from "react-toastify";
import { OrderProductsProps } from "./types";
import styles from "./styles.module.scss";

const OrderProducts = ({ order }: OrderProductsProps) => {
  const [showForm, setShowForm] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);

  const handleOpenShowForm = useCallback(() => {
    if (Boolean(order?.client)) {
      setShowForm(true);
    } else {
      toast.warning("Необходимо добавить клиента");
    }
  }, [order?.client]);

  const handleHideShowForm = useCallback(() => {
    setShowForm(false);
  }, []);

  return (
    <>
      <div className={styles.products}>
        <Grid>
          <Grid.Item col={7}>
            <Flex alignItems="center" columnGap="xs">
              <span className={styles.productsLabel}>Товары</span>
              <Input placeholder="Поиск" variant="small" />
              <Button
                onClick={() => setShowProductModal(true)}
                variant="secondary"
              >
                Склад
              </Button>
            </Flex>
          </Grid.Item>
        </Grid>
        {/*<Table*/}
        {/*  className={styles.table}*/}
        {/*  header={*/}
        {/*    <Table.Row>*/}
        {/*      <Table.Cell>Название работы</Table.Cell>*/}
        {/*      <Table.Cell>Исполнители/Ответственный</Table.Cell>*/}
        {/*      <Table.Cell>Норма времени</Table.Cell>*/}
        {/*      <Table.Cell>Цена 1 НЧ</Table.Cell>*/}
        {/*      <Table.Cell>Без скидки</Table.Cell>*/}
        {/*      <Table.Cell>Скидка</Table.Cell>*/}
        {/*      <Table.Cell>Стоимость</Table.Cell>*/}
        {/*    </Table.Row>*/}
        {/*  }*/}
        {/*>*/}
        {/*  {[1, 2].map((_, index) => (*/}
        {/*    <Table.Row key={index}>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*      <Table.Cell>3698</Table.Cell>*/}
        {/*    </Table.Row>*/}
        {/*  ))}*/}
        {/*</Table>*/}
      </div>

      <ProductForm isOpen={showForm} onClose={handleHideShowForm} />
      <ProductOrderModal
        isOpen={showProductModal}
        onClose={() => setShowProductModal(false)}
      />
    </>
  );
};

export default OrderProducts;
