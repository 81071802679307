import Navigation from "@/components/Navigation";
import Logo from "@/components/Logo";
import UserInfo from "@/components/UserInfo";
import SubdivisionsList from "@/components/SubdivisionsList";
import { Scrollbar } from "react-scrollbars-custom";
import styles from "./styles.module.scss";

const Sidebar = () => (
  <aside className={styles.aside}>
    <Logo />
    <SubdivisionsList />
    <Scrollbar>
      <Navigation />
    </Scrollbar>
    <UserInfo />
  </aside>
);

export default Sidebar;
