import { useMemo } from "react";
import { PaginationProps } from "./types";
import IconButton from "@/components/UI/IconButton";
import IconArrowRight from "@/components/Icons/IconArrowRight";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import Select from "@/components/UI/Select";
import styles from "./styles.module.scss";

const Pagination = ({
  pageSize,
  activePage,
  pageSizeOptions = [20, 50, 100],
  className,
  onSizeChange,
  onPageChange,
  totalItems,
  totalPages,
}: PaginationProps) => {
  const pSize =
    pageSize * activePage < 1
      ? 1
      : pageSize * activePage > totalItems
        ? totalItems
        : pageSize * activePage;

  const selectOptions = useMemo(() => {
    return [...Array(totalPages).keys()].map((i) => ({ value: ++i, label: i }));
  }, [totalPages]);

  return (
    <div className={styles.pagination}>
      <div className={styles.paginationCol}>
        <span className={styles.paginationGrey}>Пункты</span>{" "}
        {activePage * pageSize - pageSize + 1}-{pSize} из {totalItems}
      </div>
      <div className={styles.paginationCol}>
        <Select
          size="sm"
          className={styles.paginationSelect}
          placeholder={activePage}
          options={selectOptions}
          onChange={(e) => {
            onPageChange(Number(e?.value));
          }}
        />
        <span>из {totalPages} страниц</span>
        <div className={styles.paginationActions}>
          <IconButton
            disabled={activePage === 1}
            variant="empty"
            icon={IconArrowLeft}
            label="Предыдущая страница"
            onClick={() => onPageChange(activePage - 1)}
          />
          <IconButton
            disabled={activePage === totalPages || totalPages === 0}
            variant="empty"
            icon={IconArrowRight}
            label="Следующая страница"
            onClick={() => onPageChange(activePage + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
