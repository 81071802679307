export const ENDPOINT = "/payment";

export enum PaymentTypeEnum {
  Receipt = "receipt",
  Withdrawal = "withdrawal",
  Transfer = "transfer",
}

export const paymentTypes = {
  [PaymentTypeEnum.Receipt]: "Поступление",
  [PaymentTypeEnum.Withdrawal]: "Вывод",
  [PaymentTypeEnum.Transfer]: "Перевод",
};
