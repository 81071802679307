import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import type { OrderPaymentFormModalProps } from "./types";
import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import Grid from "@/components/UI/Grid";
import Input from "@/components/UI/Input";
import Select from "@/components/UI/Select";
import { useOnce } from "@/hooks";
import { getPaymentTerminals } from "@/features/PaymentTerminal/service";
import { useState } from "react";
import { PaymentTerminalEntity } from "@/features/PaymentTerminal/types";

const OrderPaymentFormModal = ({
  open,
  onClose,
}: OrderPaymentFormModalProps) => {
  const [paymentTerminal, setPaymentTerminal] =
    useState<PaymentTerminalEntity[]>();
  const handleSubmit = async () => {};

  useOnce(() => {
    getPaymentTerminals({
      pageNumber: 1,
      pageSize: 100,
    }).then((res) => {
      setPaymentTerminal(res?.items);
    });
  });

  return (
    <Modal className={styles.modal} isOpen={open}>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Платеж</h2>
            </ModalHeader>
            <ModalBody className={styles.jobs}>
              <Grid>
                <Grid.Item col={7}>
                  <Grid>
                    <Grid.Item col={12}>
                      <Select
                        options={paymentTerminal?.map((paymentTermina) => ({
                          label: paymentTermina.title,
                          value: paymentTermina._id,
                        }))}
                        label="Касса"
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Input label="Терминал" placeholder="0 ₽" />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select label="Выбор терминала" />
                    </Grid.Item>
                    <Grid.Item col={12}>
                      <Input label="Принято наличными" placeholder="0 ₽" />
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button>Принять оплату</Button>
                <Button onClick={onClose} variant="secondary">
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default OrderPaymentFormModal;
