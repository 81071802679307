import Input from "@/components/UI/Input";
import Grid from "@/components/UI/Grid";
import InputPhone from "@/components/UI/InputPhone";
import {
  FormFields,
  legalAgentFormInitialValues,
  legalEntityTypes,
} from "./constants";
import { LegalAgent } from "@/types";
import AgentEmail from "../AgentEmail";
import { Form, Formik } from "formik";
import styles from "@/features/Agent/components/AgentForm/styles.module.scss";
import { Scrollbar } from "react-scrollbars-custom";
import Container from "@/components/Container";
import { If } from "@/components/ConditionalRendering/If";
import InputNumber from "@/components/UI/InputNumber";
import AgentSocialMedia from "@/features/Agent/components/AgentSocialMedia";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { PATHS, socialMedias } from "@/constants";
import { createLegalAgent } from "@/features/Agent/service";
import { toast } from "react-toastify";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { AgentLegalFormProps } from "./types";
import { legalAgentFormSchema } from "./schema";
import Select from "@/components/UI/Select";
import { agentsType } from "@/features/Agent/components/AgentForm/constants";
import AgentRepresentative from "../AgentRepresentative";
import Cars from "src/features/Car/components/Cars";
import { isAxiosError } from "axios";
import {
  agentFormTitles,
  AgentType,
  agentTypes,
} from "@/features/Agent/constants";

const AgentLegalForm = ({
  agentData,
  isEdit,
  showTitle = true,
}: AgentLegalFormProps) => {
  const navigate = useNavigate();
  const [agent, setAgent] = useState<LegalAgent>(
    // @ts-ignore
    legalAgentFormInitialValues,
  );
  const [isLoading, setIsLoading] = useState(isEdit);
  const [isFetching, setIsFetching] = useState(false);

  const goToAgentsListPage = () => navigate(PATHS.AGENTS);

  const changeAgentCreatePage = (agentType: AgentType) =>
    navigate(`/agents/create/${agentType}`, {
      state: {
        title: agentFormTitles[agentType],
      },
    });

  useEffect(() => {
    if (agentData) {
      setAgent(agentData);
    }
  }, [agentData]);

  const handleOnSubmit = async (values: LegalAgent) => {
    if (isFetching) return;

    setIsFetching(true);

    const data = {
      ...values,
      cars: values.cars?.map((car) => car._id),
      representative: values.representative?.map((item) => item._id),
    };

    try {
      if (isEdit) {
        //await updateIndividualAgent(String(agentData?._id), values);
        toast.success("Контрагент изменен");
      } else {
        // @ts-ignore
        await createLegalAgent(data);
        toast.success("Контрагент добавлен");
      }

      goToAgentsListPage();
    } catch (err) {
      setIsFetching(false);

      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <div className={styles.container}>
      <Formik
        initialValues={agent}
        enableReinitialize
        onSubmit={handleOnSubmit}
        validationSchema={legalAgentFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
          <Form className={styles.form}>
            <Scrollbar>
              <Container className={styles.formTop} center>
                <Flex flexDirection="column" rowGap="lg">
                  <If condition={showTitle}>
                    <h2 className={styles.title}>Основная информация</h2>
                  </If>
                  <Grid gap="sm">
                    <Grid.Item col={6}>
                      <Select
                        defaultValue={{
                          label: agentTypes[AgentType.Legal],
                          value: AgentType.Legal,
                        }}
                        label="Тип контрагента"
                        onChange={(e) => {
                          changeAgentCreatePage(e!.value);
                        }}
                        options={[
                          {
                            label: agentTypes[AgentType.Individual],
                            value: AgentType.Individual,
                          },
                          {
                            label: agentTypes[AgentType.Legal],
                            value: AgentType.Legal,
                          },
                        ]}
                        placeholder="Выберите из списка"
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.Role]}
                        label="Тип агента"
                        name={FormFields.Role}
                        onChange={(e) => {
                          setFieldValue(FormFields.Role, e?.value);
                        }}
                        placeholder="Выберите из списка"
                        options={agentsType}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <InputPhone
                        error={errors[FormFields.Phone]}
                        label="Основной телефон"
                        name={FormFields.Phone}
                        onChange={handleChange}
                        placeholder="Введите номер телефона"
                        value={values.phone}
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <Select
                        error={errors[FormFields.LegalEntityType]}
                        name={FormFields.LegalEntityType}
                        label="Тип юр. лица"
                        placeholder="Выберите соц. сеть"
                        options={legalEntityTypes.map(({ id, value }) => ({
                          label: value,
                          value: id,
                        }))}
                        onChange={(e) =>
                          setFieldValue(FormFields.LegalEntityType, e?.value)
                        }
                      />
                    </Grid.Item>
                    <Grid.Item col={4}>
                      <AgentEmail />
                    </Grid.Item>
                  </Grid>
                  <Grid gap="sm">
                    <Grid.Item col={6}>
                      <Input
                        error={errors[FormFields.Title]}
                        label="Название"
                        maxLength={200}
                        name={FormFields.Title}
                        onChange={handleChange}
                        placeholder="Введите наименование"
                        value={values.title}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Input
                        error={errors[FormFields.INN]}
                        label="ИНН"
                        maxLength={80}
                        name={FormFields.INN}
                        onChange={handleChange}
                        placeholder="Введите ИНН"
                        value={values.inn}
                      />
                    </Grid.Item>
                  </Grid>
                  <Grid gap="sm">
                    <Grid.Item col={6}>
                      <Input
                        error={errors[FormFields.OfficialAddress]}
                        label="Юридический адрес"
                        name={FormFields.OfficialAddress}
                        onChange={handleChange}
                        placeholder="Введите юридический адрес"
                        value={values.officialAddress}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Input
                        error={errors[FormFields.MailAddress]}
                        label="Почтовый адрес"
                        name={FormFields.MailAddress}
                        onChange={handleChange}
                        placeholder="Введите почтовый адрес"
                        value={values.mailAddress}
                      />
                    </Grid.Item>
                  </Grid>
                  <InputNumber
                    error={errors[FormFields.Discount]}
                    label="Скидка на работы/услуги"
                    name={FormFields.Discount}
                    placeholder="Введите размер скидки"
                    onChange={handleChange}
                    value={values.discount || 0}
                  />

                  <Flex flexDirection="column" rowGap="lg">
                    <AgentSocialMedia />
                    <AgentRepresentative title="Представитель" />
                    <Cars />
                  </Flex>
                </Flex>
              </Container>
            </Scrollbar>

            <Flex
              className={styles.formActions}
              alignItems="center"
              columnGap="sm"
            >
              <Button
                disabled={isFetching}
                isLoading={isFetching}
                type="submit"
              >
                Сохранить
              </Button>
              <Button
                disabled={isFetching}
                onClick={goToAgentsListPage}
                variant="secondary"
              >
                Закрыть
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AgentLegalForm;
