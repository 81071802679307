import { useEffect, useState } from "react";
import Meta from "@/components/Meta";
import Grid from "@/components/UI/Grid";
import Flex from "@/components/UI/Flex";
import { AutoComplete } from "rsuite";
import Button from "@/components/UI/Button";
import IconButton from "@/components/UI/IconButton";
import IconPrint from "@/components/Icons/IconPrint";
import IconAdd from "@/components/Icons/IconAdd";
import OrderStatuses from "../OrderStatuses";
import { useParams } from "react-router";
import Tabs from "@/components/UI/Tabs";
import { OrderTab, orderTabs } from "@/features/Order/constants";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import OrderJobs from "@/features/Order/components/OrderJobs";
import { getOrderByID, updateOrder } from "@/features/Order/services";
import {
  OrderEntity,
  OrderNoteEntity,
  OrderStatusEnum,
} from "@/features/Order/types";
import OrderProducts from "@/features/Order/components/OrderProducts";
import { useEventListener, useOnce } from "@/hooks";
import { EVENTS } from "@/constants";
import styles from "./styles.module.scss";
import cn from "classnames";
import { If } from "@/components/ConditionalRendering/If";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { Agent, IndividualAgent, UserType } from "@/types";
import {
  getJobsTotalPrice,
  getProductsTotalPrice,
} from "@/features/Order/utils";
import { JobEntity } from "@/features/Job/types";
import { ProductEntity } from "@/features/Product/types";
import { AgentType } from "@/features/Agent/constants";
import {
  searchIndividualAgents,
  searchLegalAgents,
} from "@/features/Agent/service";
import { toast } from "react-toastify";
import IconEdit from "@/components/Icons/IconEdit";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import {
  addCar,
  searchCarsByBrand,
  searchCarsByModel,
} from "@/features/Car/services";
import {
  CarEntity,
  CarModelEntity,
} from "@/features/Car/components/Cars/types";
import { isString } from "lodash";
import { Scrollbar } from "react-scrollbars-custom";
import IconLoading from "@/components/Icons/IconLoading";
import OrderNotes from "@/features/Order/components/OrderNotes";
import OrderPaymentFormModal from "@/features/Order/components/OrderPaymentFormModal";
import { OrderCalendarForm } from "@/features/Order/components/OrderForm/OrderCalendarForm";

const OrderForm = () => {
  const { id } = useParams();
  const [agentType, setAgentType] = useState<AgentType>(AgentType.Individual);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentFormModal, setShowPaymentFormModal] = useState(false);
  const { currentSubdivision } = useAppProvider();
  const [brand, setBrand] = useState<Partial<CarEntity> | null>();
  const [model, setModel] = useState<Partial<CarModelEntity> | null>(null);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [cars, setCars] = useState<CarEntity[]>([]);
  const [carModels, setCarModels] = useState<CarModelEntity[]>([]);
  const [order, setOrder] = useState<OrderEntity>();
  const [orderTab, setOrderTab] = useState<OrderTab>(OrderTab.Job);

  const orderHasClient = Boolean(order?.client);

  const refreshData = () => {
    fetchData(id as string);
  };

  const fetchData = (id: string) => {
    getOrderByID(id)
      .then((data) => {
        setOrder(data);

        if (data?.car?.sourceCar?.brand && data?.car?.sourceCar?.model) {
          setBrand({ name: data?.car?.sourceCar?.brand });
          setModel({ name: data?.car?.sourceCar?.model });
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    setIsFetching(true);
    fetchData(id as string);
  }, [id]);

  useEventListener(EVENTS.REFRESH_DATA, refreshData);

  const handleSearchCarsByModel = async (model: string) => {
    if (!isString(model)) return;

    try {
      const data = await searchCarsByModel(brand?._id as string);
      console.log(data, "data");
      setCarModels(
        data?.map((item: CarModelEntity) => ({
          value: item,
          label: item.name,
        })),
      );
    } catch (err) {}
  };

  const handleSearchCarsByBrand = async (brand: string) => {
    if (!isString(brand)) return;

    try {
      const data = await searchCarsByBrand(brand);
      console.log(data);
      setCars(
        data?.map((item: CarEntity) => ({
          value: item,
          label: item?.name,
        })),
      );
    } catch (err) {}
  };

  const handleSearchLegalAgents = async (query: string) => {
    try {
      const data = await searchLegalAgents(query);

      setAgents(
        data?.map((item: UserType) => ({
          value: item.account,
          label: getAgentFullName(item),
        })),
      );
    } catch (err) {}
  };

  const handleSearchAgents = async (query: string) => {
    try {
      const data = await searchIndividualAgents(query);

      setAgents(
        data?.map((item: UserType) => ({
          value: item.account,
          label: getAgentFullName(item),
        })),
      );
    } catch (err) {}
  };

  const handleSetAgent = async (client: string) => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      await updateOrder(id as string, {
        client,
      });

      fetchData(id as string);
      toast.success("Клиент добавлен");
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveBrand = async () => {
    setBrand(null);
  };

  const handleRemoveModel = async () => {
    setModel(null);
  };

  const handleRemoveAgent = async () => {
    try {
      await updateOrder(id as string, {
        client: null,
      });

      // @ts-ignore
      setOrder((prev) => ({
        ...prev,
        client: null,
      }));
    } catch (err) {}
  };

  const handeSetBrand = (value: CarEntity) => {
    setBrand(value);
  };

  const handeSetModel = async (value: CarModelEntity) => {
    try {
      const car = await addCar({
        brand: brand?.name,
        model: value?.name,
      });

      await updateOrder(id as string, {
        car: {
          sourceCar: car?._id,
        },
      });

      fetchData(id as string);
      toast.success("Автомобиль добавлен");
      setModel(value);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {};

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Meta title="Создание сделки" />

        <div className={styles.wrapperTop}>
          <Flex alignItems="center" justifyContent="space-between">
            <h1 className={styles.title}>Сделка #{order?.increment}</h1>
            <div>
              <p className={styles.wrapperSubdivision}>
                {currentSubdivision?.title}
              </p>
            </div>
          </Flex>
          <Grid gap="sm" className={styles.grid}>
            <Grid.Item col={12}>
              <Flex className={styles.tabs} columnGap="xs">
                <button
                  className={cn(
                    styles.tabsBtn,
                    agentType === AgentType.Individual && styles.tabsBtnActive,
                  )}
                  onClick={() => {
                    setAgentType(AgentType.Individual);
                  }}
                >
                  Физ.лицо
                </button>
                <button
                  className={cn(
                    styles.tabsBtn,
                    agentType === AgentType.Legal && styles.tabsBtnActive,
                  )}
                  onClick={() => {
                    setAgentType(AgentType.Legal);
                  }}
                >
                  Юр.лицо
                </button>
              </Flex>
            </Grid.Item>
            <If condition={agentType === AgentType.Individual}>
              <Grid.Item col={3}>
                {isLoading ? (
                  <IconLoading />
                ) : orderHasClient ? (
                  <Flex flexDirection="column" rowGap="zero">
                    <Flex
                      alignItems="center"
                      className="text-primary"
                      columnGap="zero"
                    >
                      {getAgentFullName(
                        order?.client?.counterpartProfile as IndividualAgent,
                      )}
                      <IconButton icon={IconEdit} onClick={handleRemoveAgent} />
                    </Flex>
                    <span>{order?.client?.counterpartProfile?.phone}</span>
                  </Flex>
                ) : (
                  <Flex columnGap="xxs">
                    <Flex flexDirection="column" rowGap="xxs">
                      <AutoComplete
                        data={agents}
                        onSelect={handleSetAgent}
                        onChange={handleSearchAgents}
                        placeholder="ФИО клиента"
                      />
                      <AutoComplete
                        className={styles.autocomplete}
                        data={agents}
                        onChange={handleSearchAgents}
                        placeholder="Номер телефона"
                      />
                    </Flex>
                    <div>
                      <IconButton
                        icon={IconAdd}
                        onClick={() => null}
                        variant="secondary"
                      />
                    </div>
                  </Flex>
                )}
              </Grid.Item>
            </If>
            <If condition={agentType === AgentType.Legal}>
              <Grid.Item col={3}>
                <Flex columnGap="xxs">
                  <Flex flexDirection="column" rowGap="xxs">
                    <AutoComplete
                      data={[]}
                      placeholder="Название организации"
                    />
                    <AutoComplete
                      className={styles.autocomplete}
                      data={[]}
                      placeholder="ИНН организации"
                    />
                  </Flex>
                  <div>
                    <IconButton
                      icon={IconAdd}
                      onClick={() => null}
                      variant="secondary"
                    />
                  </div>
                </Flex>
              </Grid.Item>
              <Grid.Item col={3}>
                {isLoading ? (
                  <IconLoading />
                ) : orderHasClient ? (
                  <Flex flexDirection="column" rowGap="zero">
                    <Flex
                      alignItems="center"
                      className="text-primary"
                      columnGap="zero"
                    >
                      {getAgentFullName(
                        order?.client?.counterpartProfile as IndividualAgent,
                      )}
                      <IconButton icon={IconEdit} onClick={handleRemoveAgent} />
                    </Flex>
                    <span>{order?.client?.counterpartProfile?.phone}</span>
                  </Flex>
                ) : (
                  <Flex columnGap="xxs">
                    <Flex flexDirection="column" rowGap="xxs">
                      <AutoComplete
                        data={agents}
                        onSelect={handleSetAgent}
                        onChange={handleSearchLegalAgents}
                        placeholder="ФИО представителя"
                      />
                      <AutoComplete
                        className={styles.autocomplete}
                        data={agents}
                        onChange={handleSearchAgents}
                        placeholder="Номер телефона"
                      />
                    </Flex>
                    <div>
                      <IconButton
                        icon={IconAdd}
                        onClick={() => null}
                        variant="secondary"
                      />
                    </div>
                  </Flex>
                )}
              </Grid.Item>
            </If>
            <Grid.Item col={3}>
              <Flex columnGap="xxs">
                <Flex
                  flexDirection="column"
                  rowGap="xxs"
                  className={styles.carWrapper}
                >
                  {Boolean(brand?.name) ? (
                    <Flex flexDirection="column" rowGap="zero">
                      <Flex
                        alignItems="center"
                        className="text-primary"
                        columnGap="xxs"
                      >
                        {brand?.name}
                        <IconButton
                          size="xs"
                          icon={IconEdit}
                          onClick={handleRemoveBrand}
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <AutoComplete
                      defaultValue={order?.car?.sourceCar?.brand as string}
                      disabled={!orderHasClient}
                      data={cars}
                      onSelect={handeSetBrand}
                      onChange={handleSearchCarsByBrand}
                      placeholder="Марка автомобиля"
                    />
                  )}
                  {Boolean(model?.name) ? (
                    <Flex flexDirection="column" rowGap="zero">
                      <Flex
                        alignItems="center"
                        className="text-primary"
                        columnGap="xxs"
                      >
                        {model?.name}
                        <IconButton
                          size="xs"
                          icon={IconEdit}
                          onClick={handleRemoveModel}
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <AutoComplete
                      data={carModels}
                      defaultValue={order?.car?.sourceCar?.model as string}
                      disabled={!Boolean(brand?._id)}
                      onSelect={handeSetModel}
                      onChange={handleSearchCarsByModel}
                      placeholder="Модель автомобиля"
                    />
                  )}
                </Flex>
                <div>
                  <IconButton
                    disabled={!orderHasClient}
                    icon={IconAdd}
                    onClick={() => null}
                    variant="secondary"
                  />
                </div>
              </Flex>
            </Grid.Item>
            <Grid.Item col={3}>
              <OrderCalendarForm />
            </Grid.Item>
            <Grid.Item col={3}>
              <Flex flexDirection="column" rowGap="xs">
                {/*<span>Ответственный</span>*/}
                {/*<span>Бартенев А.</span>*/}
              </Flex>
            </Grid.Item>
          </Grid>
        </div>
        <Scrollbar>
          <div className={styles.wrapperContent}>
            <OrderStatuses currentStatus={order?.status as OrderStatusEnum} />
            <Tabs
              className={styles.wrapperTabs}
              value={orderTab}
              onSelect={setOrderTab}
            >
              {orderTabs.map(({ label, value }) => (
                <Tabs.Item key={value} value={value}>
                  {label}
                </Tabs.Item>
              ))}
            </Tabs>
            {orderTab === OrderTab.Job && (
              <>
                <OrderJobs order={order} />
                <OrderProducts order={order} />
              </>
            )}
            {orderTab === OrderTab.Notes && (
              <OrderNotes notes={order?.notes as OrderNoteEntity[]} />
            )}
          </div>
        </Scrollbar>
        <Flex className={styles.total}>
          <Flex
            className={styles.totalItem}
            flexDirection="column"
            rowGap="xxs"
          >
            <p>{order?.jobs?.length} работ</p>
            <span>{order?.jobsPriceWithDiscount || 0} ₽</span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>{order?.products?.length} товаров</p>
            <span>{order?.productsPriceWithDiscount || 0} ₽</span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>Скидка</p>
            <span>
              {Number(order?.totalPrice) -
                Number(order?.totalPriceWithDiscount) || 0}{" "}
              ₽
            </span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>Итого:</p>
            <span>{order?.totalPrice || 0} ₽</span>
          </Flex>
          <Flex
            className={styles.totalItem}
            rowGap="xxs"
            flexDirection="column"
          >
            <p>Сумма оплат:</p>
            <span>{order?.totalPriceWithDiscount || 0} ₽</span>
          </Flex>
          <If condition={Boolean(order?.payer)}>
            <Flex
              className={styles.totalItem}
              rowGap="xxs"
              flexDirection="column"
            >
              <p>Плательщик:</p>
              <span>
                {getAgentFullName(order?.payer as unknown as UserType)}
              </span>
            </Flex>
          </If>
          <Flex justifyContent="flex-end" className={styles.totalItem}>
            <Button
              onClick={() => setShowPaymentFormModal(true)}
              className={styles.wrapperSubmitBtn}
              variant="default"
            >
              Оплата
            </Button>
            <IconButton
              icon={IconPrint}
              label="Распечатать сделку"
              onClick={() => null}
              variant="secondary"
            />
          </Flex>
        </Flex>
      </div>

      <OrderPaymentFormModal
        open={showPaymentFormModal}
        onClose={() => setShowPaymentFormModal(false)}
      />
    </>
  );
};

export default OrderForm;
