export enum CalendarEntryColorEnum {
  Blue = "blue",
  Black = "black",
  LightPurple = "purple",
  Pink = "pink",
  Orange = "orange",
  LightGreen = "light-green",
  Green = "green",
  Red = "red",
  Brown = "brown",
  Teal = "teal",
}

export enum CalendarEntryTypeEnum {
  Order = "order",
  Reserve = "reserve",
  Blocking = "blocking",
}

export interface CalendarEntity {
  marker: CalendarEntryColorEnum;
  serviceBay?: string;
  manager?: string;
  startAt: string;
  endAt: string;
  type: CalendarEntryTypeEnum;
  contact?: string;
  contactPhone?: string;
  contactCarModel?: string;
  order?: string;
  comment?: string;
}
