const IconCalendar = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 4.79166C6.32533 4.79166 6.04199 4.50832 6.04199 4.16666V1.66666C6.04199 1.32499 6.32533 1.04166 6.66699 1.04166C7.00866 1.04166 7.29199 1.32499 7.29199 1.66666V4.16666C7.29199 4.50832 7.00866 4.79166 6.66699 4.79166Z"
        fill="currentColor"
      />
      <path
        d="M13.333 4.79166C12.9913 4.79166 12.708 4.50832 12.708 4.16666V1.66666C12.708 1.32499 12.9913 1.04166 13.333 1.04166C13.6747 1.04166 13.958 1.32499 13.958 1.66666V4.16666C13.958 4.50832 13.6747 4.79166 13.333 4.79166Z"
        fill="currentColor"
      />
      <path
        d="M7.08333 12.0833C6.975 12.0833 6.86667 12.0583 6.76667 12.0167C6.65833 11.975 6.575 11.9167 6.49167 11.8417C6.34167 11.6833 6.25 11.475 6.25 11.25C6.25 11.1417 6.275 11.0333 6.31667 10.9333C6.35833 10.8333 6.41667 10.7417 6.49167 10.6583C6.575 10.5833 6.65833 10.525 6.76667 10.4833C7.06667 10.3583 7.44167 10.425 7.675 10.6583C7.825 10.8167 7.91667 11.0333 7.91667 11.25C7.91667 11.3 7.90833 11.3583 7.9 11.4167C7.89167 11.4667 7.875 11.5167 7.85 11.5667C7.83333 11.6167 7.80833 11.6667 7.775 11.7167C7.75 11.7583 7.70833 11.8 7.675 11.8417C7.51667 11.9917 7.3 12.0833 7.08333 12.0833Z"
        fill="currentColor"
      />
      <path
        d="M10.0003 12.0833C9.89199 12.0833 9.78366 12.0583 9.68366 12.0167C9.57532 11.975 9.49199 11.9166 9.40866 11.8416C9.25866 11.6833 9.16699 11.475 9.16699 11.25C9.16699 11.1417 9.19199 11.0333 9.23366 10.9333C9.27533 10.8333 9.33366 10.7417 9.40866 10.6583C9.49199 10.5833 9.57532 10.525 9.68366 10.4833C9.98366 10.35 10.3587 10.425 10.592 10.6583C10.742 10.8167 10.8337 11.0333 10.8337 11.25C10.8337 11.3 10.8253 11.3583 10.817 11.4167C10.8087 11.4667 10.792 11.5167 10.767 11.5667C10.7503 11.6167 10.7253 11.6667 10.692 11.7167C10.667 11.7583 10.6253 11.8 10.592 11.8416C10.4337 11.9916 10.217 12.0833 10.0003 12.0833Z"
        fill="currentColor"
      />
      <path
        d="M12.9163 12.0833C12.808 12.0833 12.6997 12.0583 12.5997 12.0167C12.4913 11.975 12.408 11.9166 12.3247 11.8416C12.2913 11.8 12.258 11.7583 12.2247 11.7167C12.1913 11.6667 12.1663 11.6167 12.1497 11.5667C12.1247 11.5167 12.108 11.4667 12.0997 11.4167C12.0913 11.3583 12.083 11.3 12.083 11.25C12.083 11.0333 12.1747 10.8167 12.3247 10.6583C12.408 10.5833 12.4913 10.525 12.5997 10.4833C12.908 10.35 13.2747 10.425 13.508 10.6583C13.658 10.8167 13.7497 11.0333 13.7497 11.25C13.7497 11.3 13.7413 11.3583 13.733 11.4167C13.7247 11.4667 13.708 11.5167 13.683 11.5667C13.6663 11.6167 13.6413 11.6667 13.608 11.7167C13.583 11.7583 13.5413 11.8 13.508 11.8416C13.3497 11.9916 13.133 12.0833 12.9163 12.0833Z"
        fill="currentColor"
      />
      <path
        d="M7.08333 15C6.975 15 6.86667 14.975 6.76667 14.9333C6.66667 14.8917 6.575 14.8333 6.49167 14.7583C6.34167 14.6 6.25 14.3833 6.25 14.1667C6.25 14.0583 6.275 13.95 6.31667 13.85C6.35833 13.7417 6.41667 13.65 6.49167 13.575C6.8 13.2667 7.36667 13.2667 7.675 13.575C7.825 13.7333 7.91667 13.95 7.91667 14.1667C7.91667 14.3833 7.825 14.6 7.675 14.7583C7.51667 14.9083 7.3 15 7.08333 15Z"
        fill="currentColor"
      />
      <path
        d="M10.0003 15C9.78366 15 9.56699 14.9083 9.40866 14.7583C9.25866 14.6 9.16699 14.3833 9.16699 14.1667C9.16699 14.0583 9.19199 13.95 9.23366 13.85C9.27533 13.7417 9.33366 13.65 9.40866 13.575C9.71699 13.2667 10.2837 13.2667 10.592 13.575C10.667 13.65 10.7253 13.7417 10.767 13.85C10.8087 13.95 10.8337 14.0583 10.8337 14.1667C10.8337 14.3833 10.742 14.6 10.592 14.7583C10.4337 14.9083 10.217 15 10.0003 15Z"
        fill="currentColor"
      />
      <path
        d="M12.9163 15C12.6997 15 12.483 14.9083 12.3247 14.7583C12.2497 14.6833 12.1913 14.5917 12.1497 14.4834C12.108 14.3834 12.083 14.275 12.083 14.1667C12.083 14.0584 12.108 13.95 12.1497 13.85C12.1913 13.7417 12.2497 13.65 12.3247 13.575C12.5163 13.3834 12.808 13.2917 13.0747 13.35C13.133 13.3583 13.183 13.375 13.233 13.4C13.283 13.4167 13.333 13.4417 13.383 13.475C13.4247 13.5 13.4663 13.5417 13.508 13.575C13.658 13.7334 13.7497 13.95 13.7497 14.1667C13.7497 14.3834 13.658 14.6 13.508 14.7583C13.3497 14.9083 13.133 15 12.9163 15Z"
        fill="currentColor"
      />
      <path
        d="M17.0837 8.19998H2.91699C2.57533 8.19998 2.29199 7.91665 2.29199 7.57498C2.29199 7.23331 2.57533 6.94998 2.91699 6.94998H17.0837C17.4253 6.94998 17.7087 7.23331 17.7087 7.57498C17.7087 7.91665 17.4253 8.19998 17.0837 8.19998Z"
        fill="currentColor"
      />
      <path
        d="M13.3333 18.9583H6.66667C3.625 18.9583 1.875 17.2083 1.875 14.1667V7.08332C1.875 4.04166 3.625 2.29166 6.66667 2.29166H13.3333C16.375 2.29166 18.125 4.04166 18.125 7.08332V14.1667C18.125 17.2083 16.375 18.9583 13.3333 18.9583ZM6.66667 3.54166C4.28333 3.54166 3.125 4.69999 3.125 7.08332V14.1667C3.125 16.55 4.28333 17.7083 6.66667 17.7083H13.3333C15.7167 17.7083 16.875 16.55 16.875 14.1667V7.08332C16.875 4.69999 15.7167 3.54166 13.3333 3.54166H6.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCalendar;
