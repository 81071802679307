import { useEffect, useState } from "react";
import PageLoader from "@/components/PageLoader";
import { useParams } from "react-router";
import Header from "./components/Header";
import Tabs from "@/components/UI/Tabs";
import AgentNotes from "./components/AgentNotes";
import AgentsDealsListTable from "./components/AgentsDealsListTable";
import { AgentDetailTabs } from "@/pages/AgentDetail/types";
import AgentBankAccounts from "./components/AgentBankAccounts";
import AgentCallsListTable from "./components/AgentCallsListTable";
import AgentCalendar from "./components/AgentCalendar";
import AgentsOrganizationsListTable from "./components/AgentsOrganizationsListTable";
import { tabs } from "./constants";
import Container from "@/components/Container";
import { fetchAgentById } from "@/pages/AgentDetail/service";
import { Agent, CounterpartTypeEnum, IndividualAgent } from "@/types";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import styles from "./styles.module.scss";
import { AgentForm } from "@/features/Agent";
import { AgentType } from "@/features/Agent/constants";

const AgentDetail = () => {
  const { agentId, agentType } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<AgentDetailTabs>(
    AgentDetailTabs.Main,
  );
  const [agent, setAgent] = useState<Agent>();

  useEffect(() => {
    fetchAgentById(String(agentId), agentType as CounterpartTypeEnum).then(
      (res) => {
        setAgent(res);
        setIsLoading(false);
      },
    );
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={styles.content}>
      <Header
        breadcrumbs={[
          { label: "Контрагенты", path: "/agents" },
          { label: "Просмотр контрагента" },
        ]}
        title={getAgentFullName(agent as IndividualAgent)}
      />
      <Container className={styles.container} center>
        <Tabs value={currentTab} onSelect={setCurrentTab}>
          {tabs.map(({ label, value }) => (
            <Tabs.Item key={value} value={value}>
              {label}
            </Tabs.Item>
          ))}
        </Tabs>
      </Container>

      {currentTab === AgentDetailTabs.Main && (
        <AgentForm
          agentData={agent}
          agentType={agentType as AgentType}
          isEdit
        />
      )}
      {currentTab === AgentDetailTabs.Organizations && (
        <AgentsOrganizationsListTable />
      )}
      {currentTab === AgentDetailTabs.Notes && <AgentNotes />}
      {currentTab === AgentDetailTabs.Calendar && <AgentCalendar />}
      {currentTab === AgentDetailTabs.Calls && <AgentCallsListTable />}
      {currentTab === AgentDetailTabs.Deals && <AgentsDealsListTable />}
      {currentTab === AgentDetailTabs.BankAccounts && <AgentBankAccounts />}
    </div>
  );
};

export default AgentDetail;
