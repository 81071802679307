import { isValidElement } from "react";
import { v4 } from "uuid";
import cn from "classnames";
import type { InputProps } from "./types";
import styles from "./styles.module.scss";

const Input = (props: InputProps) => {
  const {
    id = v4(),
    error,
    endIcon: EndIcon,
    label,
    light,
    maxLength,
    variant,
  } = props;

  return (
    <label className={styles.label} htmlFor={id}>
      {Boolean(label) && label}
      <div className={styles.wrapper}>
        <input
          id={id}
          className={cn(
            styles.input,
            {
              [styles.inputInvalid]: Boolean(error),
              [styles.inputSmall]: variant === "small",
              [styles.inputEmpty]: variant === "empty",
              [styles.inputLight]: light,
              [styles.inputEndIcon]: Boolean(EndIcon),
              [styles.inputSmaller]: variant === "smaller",
            },
            props.className,
          )}
          onChange={props.onChange}
          {...props}
        />
        {EndIcon && (
          <div className={styles.endIcon}>
            <EndIcon />
          </div>
        )}
      </div>

      {error && <p className={styles.error}>{error}</p>}
    </label>
  );
};

export default Input;
