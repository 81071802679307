import TableCell from "./TableCell";
import cn from "classnames";
import { TableRowProps, TableCellProps } from "./types";
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from "react";
import styles from "./styles.module.scss";

const TableRow = ({
  children,
  className,
  onClick,
}: PropsWithChildren<TableRowProps>) => {
  const enhancedChildren = Children.map(children, (child) => {
    if (!isValidElement(child) || child.type !== TableCell) {
      return child;
    }

    return cloneElement(child, {} as TableCellProps);
  });

  return (
    <tr className={cn(styles.row, className)} onClick={onClick}>
      {enhancedChildren}
    </tr>
  );
};

export default TableRow;
