import type { AgentFormProps } from "./types";
import AgentIndividualForm from "../AgentIndividualForm";
import AgentLegalForm from "../AgentLegalForm";
import { CounterpartTypeEnum } from "@/types";
import { AgentType } from "@/features/Agent/constants";

const AgentForm = ({
  agentData,
  agentType = AgentType.Individual,
  showTitle = true,
  isEdit,
}: AgentFormProps) => {
  if (agentType === AgentType.Individual) {
    return (
      <AgentIndividualForm
        // @ts-ignore
        agentData={agentData}
        isEdit={isEdit}
        showTitle={showTitle}
      />
    );
  }

  return <AgentLegalForm />;
};

export default AgentForm;
