export enum SettingsTab {
  Services = "services",
}

export const settingsTabs = [
  {
    label: "Автосервисы",
    value: SettingsTab.Services,
  },
];
