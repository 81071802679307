import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import { CalendarFormModalProps } from "./types";
import { CalendarMarkers } from "./components/CalendarMarkers";
import Textarea from "@/components/UI/Textarea";
import Tabs from "@/components/UI/Tabs";
import { initialValues, FormFields } from "./constants";
import { calendarTabs } from "@/features/Calendar/components/CalendarFormModal/constants";
import { useOnce } from "@/hooks";
import { fetchCarServiceBay } from "@/features/Car/services";
import styles from "./styles.module.scss";
import { useState } from "react";
import { CarServiceBayEntity } from "@/features/Car/types";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { fetchOrdersByClient, getOrders } from "@/features/Order/services";
import { OrderEntity } from "@/features/Order/types";
import { useAppSelector } from "@/store";
import { selectUser } from "@/store/slices/userSlice";
import DatePicker from "@/components/UI/DatePicker";
import { getBaseDate } from "@/utils/date";
import { Form, Formik } from "formik";
import {
  CalendarEntity,
  CalendarEntryTypeEnum,
} from "@/features/Calendar/types";
import { fetchCreateCalendarEntity } from "@/features/Calendar/services";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";

export const CalendarFormModal = ({
  isOpen,
  onClose,
}: CalendarFormModalProps) => {
  const user = useAppSelector(selectUser);
  const [calendarEntity, setCalendarEntity] = useState<CalendarEntity>(
    // @ts-ignore
    () => initialValues,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [carServiceBay, setCarServiceBay] = useState<CarServiceBayEntity[]>([]);
  const [orders, setOrders] = useState<OrderEntity[]>([]);
  const [ordersByClient, setOrdersByClient] = useState<OrderEntity[]>([]);

  useOnce(() => {
    Promise.all([
      fetchCarServiceBay(),
      getOrders({
        pageSize: 100,
        pageNumber: 1,
      }),
      fetchOrdersByClient(user?._id as string),
    ])
      .then(([car, orders, ordersByClient]) => {
        setOrders(orders?.items);
        setCarServiceBay(car?.items);
        setOrdersByClient(ordersByClient?.items);
        console.log(ordersByClient);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const handleSubmit = async (values: CalendarEntity) => {
    setIsFetching(true);

    try {
      await fetchCreateCalendarEntity(values);
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Запись добавлена в календарь");
      onClose?.();
    } catch (err) {
      setIsFetching(false);

      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        //@ts-ignore
        initialValues={calendarEntity}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Создание записи</h2>
            </ModalHeader>
            <ModalBody>
              {isLoading ? (
                <LoadingIndicatorPage />
              ) : (
                <Flex flexDirection="column">
                  <Flex>
                    <CalendarMarkers
                      // @ts-ignore
                      variant={values[FormFields.Marker]}
                      onSelect={(value) => {
                        setFieldValue(FormFields.Marker, value);
                      }}
                    />
                  </Flex>
                  <Grid>
                    <Grid.Item col={12}>
                      <Select
                        label="Пост/Менеджер"
                        options={ordersByClient.map((item) => ({
                          label: item.increment,
                          value: item._id,
                        }))}
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <DatePicker
                        onChange={(e) => {}}
                        placeholder="Время начала"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <DatePicker
                        placeholder="Дата начала"
                        name={FormFields.StartAt}
                        onChange={(value: Date | null) => {
                          setFieldValue(
                            FormFields.StartAt,
                            value ? new Date(value) : null,
                          );
                        }}
                        // @ts-ignore
                        date={values[FormFields.StartAt]}
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <DatePicker
                        onChange={() => null}
                        placeholder="Время завершения"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                      />
                    </Grid.Item>
                    <Grid.Item col={3}>
                      <DatePicker
                        placeholder="Дата завершения"
                        name={FormFields.EndAt}
                        onChange={(value: Date | null) => {
                          setFieldValue(
                            FormFields.EndAt,
                            value ? new Date(value) : null,
                          );
                        }}
                        // @ts-ignore
                        date={values[FormFields.EndAt]}
                      />
                    </Grid.Item>
                  </Grid>
                  <Grid>
                    <Grid.Item col={6}>
                      <Select
                        label="Контакт"
                        options={carServiceBay.map((item) => ({
                          label: item.title,
                          value: item._id,
                        }))}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        label="Сделка контакта"
                        options={orders.map((item) => ({
                          label: `#${item.increment} от ${getBaseDate(item.createdAt)}`,
                          value: item._id,
                        }))}
                      />
                    </Grid.Item>
                  </Grid>
                  <Tabs
                    className={styles.wrapperTabs}
                    onSelect={(value) => {
                      setFieldValue(FormFields.Type, value);
                    }}
                    // @ts-ignore
                    value={values[FormFields.Type]}
                  >
                    {calendarTabs.map(({ label, value }) => (
                      <Tabs.Item key={value} value={value}>
                        {label}
                      </Tabs.Item>
                    ))}
                  </Tabs>

                  {calendarEntity.type === CalendarEntryTypeEnum.Blocking && (
                    <></>
                  )}
                  {calendarEntity.type === CalendarEntryTypeEnum.Order && (
                    <>
                      <Textarea
                        name={FormFields.Comment}
                        onChange={handleChange}
                        maxLength={300}
                        label="Комментарий"
                        placeholder="Оставьте комментарий"
                      />
                    </>
                  )}
                  {calendarEntity.type === CalendarEntryTypeEnum.Reserve && (
                    <></>
                  )}
                </Flex>
              )}
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center">
                <Button disabled={isFetching} type="submit">
                  Сохранить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
