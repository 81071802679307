import { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import Input from "@/components/UI/Input";
import { FormFields } from "@/pages/Auth/constants";
import Button from "@/components/UI/Button";
import { resetPasswordFormSchema } from "./schema";
import Flex from "@/components/UI/Flex";
import { toast } from "react-toastify";
import { requestPasswordReset } from "@/features/Auth/service";
import { RequestResetPasswordFormData } from "@/features/Auth/types";
import { If } from "@/components/ConditionalRendering/If";
import { AxiosError, isAxiosError } from "axios";
import styles from "./styles.module.scss";
import { CarServiceSubdivisionEntity } from "@/features/Subdivision/types";
import { getErrorMessage } from "@/utils/error";

const initialValues = {
  email: "",
};

const ResetPasswordForm = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [timer, setTimer] = useState(60);

  const handleResetPassword = async (
    values: RequestResetPasswordFormData,
    { resetForm }: FormikHelpers<RequestResetPasswordFormData>,
  ) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await requestPasswordReset(values);
      setIsReseted(true);
      toast.success("Код успешно сброшен");
    } catch (err) {
      toast.error(getErrorMessage(err as AxiosError));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleResetPassword}
        validationSchema={resetPasswordFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>Код для сброса пароля</h1>
            <If condition={isReseted}>
              <p className={styles.formDescription}>
                На почту{" "}
                <span className={styles.formEmail}>{values.email}</span>{" "}
                отправлен <br /> код подтверждения для сброса пароля
              </p>
            </If>
            <Input
              error={errors.email}
              name={FormFields.Email}
              type="text"
              disabled={isFetching || isReseted}
              placeholder="Логин"
              onChange={handleChange}
              value={values.email}
            />
            {/*<If condition={isReseted}>*/}
            {/*  <p className={styles.formTimer}>Отправить код повторно (60с)</p>*/}
            {/*</If>*/}
            <If condition={!isReseted}>
              <Flex columnGap="sm">
                <Button
                  className={styles.formBtn}
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Подтвердить
                </Button>
                {/*<Button*/}
                {/*  className={styles.formBtn}*/}
                {/*  disabled={isFetching}*/}
                {/*  type="button"*/}
                {/*  variant="secondary"*/}
                {/*>*/}
                {/*  Отмена*/}
                {/*</Button>*/}
              </Flex>
            </If>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
