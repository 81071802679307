import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import { OrderCatalogJobModalProps } from "./types";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import JobCategories from "@/features/Job/components/JobCategories";
import JobsListTable from "@/features/Job/components/JobTableList";
import styles from "./styles.module.scss";
import { useState } from "react";
import { JobEntity } from "@/features/Job/types";
import { updateOrder } from "@/features/Order/services";
import { useParams } from "react-router";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";

const OrderCatalogJobModal = ({
  open,
  onClose,
  order,
}: OrderCatalogJobModalProps) => {
  const [jobs, setJobs] = useState<Set<string>>(new Set());
  const [isFetching, setIsFetching] = useState(false);

  const handleAddJob = (job: JobEntity) => {
    if (jobs.has(job._id)) {
      jobs.delete(job._id);
    } else {
      jobs.add(job._id);
    }

    setJobs(() => new Set(jobs));
  };

  const handleUpdateJobOrders = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        jobs: order?.jobs
          ?.map((job) => ({ job: job?.job?._id }))
          .concat(Array.from(jobs).map((jobId) => ({ job: jobId }))),
      });
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success(jobs.size > 1 ? "Работы добавлены" : "Работа добавлена");
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={open}>
      <ModalHeader onClose={onClose}>
        <h2>Каталог работ</h2>
      </ModalHeader>
      <ModalBody className={styles.jobs}>
        <JobCategories height={528} />
        <JobsListTable height={528} isChecked onChecked={handleAddJob} />
      </ModalBody>
      <ModalFooter>
        <Flex>
          <Button
            disabled={!Boolean(jobs?.size) || isFetching}
            isLoading={isFetching}
            onClick={handleUpdateJobOrders}
          >
            Добавить в сделку
          </Button>
          <Button disabled={isFetching} onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default OrderCatalogJobModal;
