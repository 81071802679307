import Table from "@/components/UI/Table";
import { useEventListener, useTable } from "@/hooks";
import { isEmpty, isFunction } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getJobs } from "@/features/Job/services";
import { JobEntity } from "@/features/Job/types";
import { useSearchParams } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import { EVENTS } from "@/constants";
import { JobsListTableProps } from "./types";
import { If } from "@/components/ConditionalRendering/If";
import Checkbox from "@/components/UI/Checkbox";
import styles from "./styles.module.scss";

const JobsListTable = ({
  height,
  isChecked = false,
  onChecked,
}: JobsListTableProps) => {
  const [params] = useSearchParams();
  const categoryParam = params?.get("category") as string;
  const [category, setCategory] = useState<string>(categoryParam);
  const [isFetching, setIsFetching] = useState(true);
  const [jobs, setJobs] = useState<JobEntity[]>([]);

  // @ts-ignore
  useEventListener(EVENTS.FETCH_JOBS_BY_CATEGORY, setCategory);

  useEffect(() => {
    setCategory(categoryParam as string);
  }, [categoryParam]);

  const {
    pageNumber,
    pageSize,
    setTotalPages,
    setTotalItems,
    totalPages,
    totalItems,
    handlePageChange,
  } = useTable();

  const handleGetJobs = useCallback(() => {
    getJobs({
      category,
      pageNumber,
      pageSize,
    })
      .then((data) => {
        setJobs(data.items);
        setTotalPages(data.pagination.totalPages || 1);
        setTotalItems(data.pagination.totalItems || 0);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [category, pageNumber, pageSize]);

  useEffect(() => {
    setIsFetching(true);
    handleGetJobs();
  }, [category, pageNumber, pageSize]);

  useEventListener(EVENTS.REFRESH_DATA, handleGetJobs);

  return (
    <>
      <Scrollbar style={{ height }}>
        <Table
          className={styles.table}
          header={
            <Table.Row>
              <If condition={isChecked}>
                <Table.Cell />
              </If>
              <Table.Cell>Наименование работы</Table.Cell>
              <Table.Cell>Норма времени</Table.Cell>
              <Table.Cell>Цена 1 НЧ</Table.Cell>
              <Table.Cell>Фиксированная ЗП</Table.Cell>
              <Table.Cell>Стоимость</Table.Cell>
            </Table.Row>
          }
          isEmpty={isEmpty(jobs)}
          isFetching={isFetching}
        >
          {jobs.map((job) => (
            <Table.Row key={job._id}>
              <If condition={isChecked}>
                <Table.Cell>
                  <Checkbox
                    className={styles.checkbox}
                    onChange={() => {
                      if (isFunction(onChecked)) {
                        onChecked(job);
                      }
                    }}
                  />
                </Table.Cell>
              </If>
              <Table.Cell>{job.title}</Table.Cell>
              <Table.Cell>{job.manHours}</Table.Cell>
              <Table.Cell>{job.manHourPrice}</Table.Cell>
              <Table.Cell>{job.fixedPaymentAmount}</Table.Cell>
              <Table.Cell>{job.price}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      {/*<If condition={Boolean(jobs.length)}>*/}
      {/*  <Pagination*/}
      {/*    activePage={pageNumber}*/}
      {/*    pageSize={pageSize}*/}
      {/*    totalPages={totalPages}*/}
      {/*    totalItems={totalItems}*/}
      {/*    onPageChange={handlePageChange}*/}
      {/*  />*/}
      {/*</If>*/}
    </>
  );
};

export default JobsListTable;
