import { CounterpartRoleEnum } from "@/types";
import { agentsType } from "@/features/Agent/components/AgentForm/constants";

export const getAgentRole = (value: CounterpartRoleEnum) => {
  console.log(
    13123123,
    agentsType.find((type) => type.value === value),
  );
  return agentsType.find((type) => type.value === value);
};
