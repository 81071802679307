import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import parsePhoneNumber from "libphonenumber-js";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import Flex from "@/components/UI/Flex";
import {
  changeStatus,
  createOrder,
  getOrdersKanban,
  updateOrder,
} from "@/features/Order/services";
import {
  KanbanEntity,
  OrderEntity,
  OrderStatusEnum,
} from "@/features/Order/types";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { orderStatuses } from "@/features/Order/constants";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { If } from "@/components/ConditionalRendering/If";
import { Scrollbar } from "react-scrollbars-custom";
import { getBaseDate } from "@/utils/date";
import Grid from "@/components/UI/Grid";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import cn from "classnames";

const OrderBoard = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [board, setBoard] = useState<Record<OrderStatusEnum, OrderEntity[]>>({
    appointment: [],
    "call-back": [],
    created: [],
    consultation: [],
    declined: [],
    junk: [],
    negotiations: [],
    ready: [],
    refund: [],
    success: [],
    working: [],
  });

  const fetchItems = () => {
    setIsFetching(true);

    getOrdersKanban([
      {
        status: "created",
        page: 1,
        pageSize: 10,
      },
    ])
      .then((data) => {
        setBoard(data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const onDragEnd = async ({
    draggableId,
    destination,
    source,
    type,
  }: DropResult) => {
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const [start] = board[source.droppableId as OrderStatusEnum].splice(
      source.index,
      1,
    );

    const end =
      board[destination.droppableId as OrderStatusEnum]?.[destination.index]
        ?._id;

    board[destination.droppableId as OrderStatusEnum].splice(
      destination.index,
      0,
      start,
    );

    setBoard(board);

    const requests = [];

    requests.push(
      updateOrder(start._id, {
        sort: destination.index,
      }),
      changeStatus(draggableId, {
        newStatus: destination.droppableId,
      }),
    );

    if (end) {
      requests.push(
        updateOrder(end, {
          sort: source.index,
        }),
      );
    }

    await Promise.all(requests);
  };

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <Scrollbar>
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex className={styles.wrapper}>
          {Object.entries(board).map(([status, items], i) => (
            <Droppable droppableId={status} type="group">
              {(provided, snapshot) => (
                <div
                  key={status}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={cn(
                    styles.column,
                    snapshot.isDraggingOver && styles.columnDrag,
                  )}
                >
                  <Flex flexDirection="column" rowGap="zero">
                    <div className={styles.columnTop}>
                      <Flex alignItems="center" columnGap="sm">
                        <h2 className={styles.columnTitle}>
                          {orderStatuses[status as OrderStatusEnum]}
                        </h2>
                        <span className={styles.columnCounter}>
                          {items.length}
                        </span>
                      </Flex>
                    </div>

                    {items.map((item, i) => (
                      <Draggable
                        draggableId={item._id}
                        index={i}
                        key={item._id}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <Item
                              isDragging={snapshot.isDragging}
                              item={item}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Flex>
                </div>
              )}
            </Droppable>
          ))}
        </Flex>
      </DragDropContext>
    </Scrollbar>
  );
};

const Container = ({
  id,
  isFirstColumn,
  items,
}: {
  id: OrderStatusEnum;
  isFirstColumn: boolean;
  items: OrderEntity[];
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const handleCreateOrder = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      const { _id } = await createOrder({});
      navigate(`/orders/${_id}`);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    // @ts-ignore
    <Flex flexDirection="column" rowGap="sm">
      <Flex alignItems="center" columnGap="sm">
        <h2 className={styles.columnTitle}>{orderStatuses[id]}</h2>
        <span className={styles.columnCounter}>{items.length}</span>
      </Flex>
      <If condition={isFirstColumn}>
        <Button onClick={handleCreateOrder} endIcon={IconAdd} variant="stroke">
          Сделка
        </Button>
      </If>

      <Flex flexDirection="column" rowGap="sm">
        {items?.map((item) => (
          <SortableItem key={item._id} id={item._id} item={item} />
        ))}
      </Flex>
    </Flex>
  );
};

// @ts-ignore
function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item item={props.item} />
    </div>
  );
}

// @ts-ignore
export function Item(props) {
  const { item, isDragging } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/orders/${item._id}`);
  };

  return (
    <div
      className={cn(styles.item, isDragging && styles.itemDrag)}
      role="button"
      onClick={handleClick}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" columnGap="xs">
          <span className="text-primary">#{item.increment}</span>
          <span className="text-secondary">{getBaseDate(item.createdAt)}</span>
        </Flex>
        {/*<IconMore />*/}
      </Flex>
      <If condition={Boolean(item.client?.counterpartProfile)}>
        <Grid gap="xxs">
          <Grid.Item col={6}>
            <span className={styles.client}>
              {getAgentFullName(item.client?.counterpartProfile, false)}
            </span>
          </Grid.Item>
          <Grid.Item col={6}>
            <span className="text-secondary">
              {parsePhoneNumber(
                item.client?.counterpartProfile?.phone || "",
              )?.formatInternational()}
            </span>
          </Grid.Item>
          <Grid.Item col={6}>
            <span className="text-secondary">
              {getBaseDate(item.client?.counterpartProfile?.birthDate)}
            </span>
          </Grid.Item>
        </Grid>
      </If>
      <Flex alignItems="center" justifyContent="space-between">
        <If condition={Boolean(item.responsible?.counterpartProfile)}>
          <span className={styles.responsible}>
            {getAgentFullName(item.responsible?.counterpartProfile, false)}
          </span>
        </If>
        <span className={styles.price}>{item.totalPrice} ₽</span>
      </Flex>
    </div>
  );
}

export default OrderBoard;
