import Header from "@/components/Header";
import WarehouseArrivalTable from "../WarehouseArrivalTable";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { useNavigate } from "react-router";
import { PATHS } from "@/constants";
import styles from "./styles.module.scss";

const WarehouseArrivalForm = () => {
  const navigate = useNavigate();

  const goToWarehouseArrivalCreate = () => {
    navigate(PATHS.ARRIVAL_CREATE);
  };

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button onClick={goToWarehouseArrivalCreate} endIcon={IconAdd}>
              Поступление
            </Button>
            {/*<Button endIcon={IconAdd} variant="secondary">*/}
            {/*  Создать коррекцию*/}
            {/*</Button>*/}
          </>
        }
        showSearch={false}
        title="Поступления"
      />
      <WarehouseArrivalTable />
    </div>
  );
};

export default WarehouseArrivalForm;
